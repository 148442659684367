import { ComponentType } from 'react'
import { SimpleForm, NumberInput, useRecordContext } from 'react-admin'
import { PessimisticEdit } from '../../components/PessimisticEdit'

const PointsEditForm = () => {
    const { totalCreatorPoints, pointsAwarded } = useRecordContext()
    return (
        <SimpleForm>
            <p>Points rewarded for campaign:</p>
            <NumberInput
                label={false}
                helperText={`Current creator points: ${
                    totalCreatorPoints || '---'
                }`}
                min={
                    totalCreatorPoints - pointsAwarded > 0
                        ? 0
                        : String(totalCreatorPoints - pointsAwarded)
                }
                source="pointsAwarded"
            />
        </SimpleForm>
    )
}

const EditPointsAmount: ComponentType = () => (
    <PessimisticEdit redirect={false}>
        <PointsEditForm />
    </PessimisticEdit>
)

export default EditPointsAmount

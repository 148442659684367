import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
} from '@material-ui/core'
import { FormContext } from 'components/Form'

type SelectOption = string | number

const SelectField = ({
    options,
    label,
    source,
    style,
    ...props
}: {
    label?: string
    options: { value: SelectOption; label: string }[]
    source: string
} & SelectProps) => (
    <FormContext.Consumer>
        {({ form, handleFormChange }) => (
            <FormControl style={style} fullWidth>
                <InputLabel style={{ marginLeft: 15 }}>{label}</InputLabel>
                <Select
                    value={form[source]}
                    variant="outlined"
                    label={label}
                    onChange={(event) => {
                        handleFormChange(
                            source,
                            event.target.value as SelectOption
                        )
                    }}
                    {...props}
                >
                    {options.map((option) => (
                        <MenuItem key={label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )}
    </FormContext.Consumer>
)

export default SelectField

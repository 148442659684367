import { ComponentType } from 'react'
import { Line } from 'react-chartjs-2'
import 'chart.js/auto'

const Chart: ComponentType<{
    title: string
    labels: string[]
    values: number[]
    colors?: {
        borderColor?: string
        backgroundColor?: string
    }
}> = ({ title, labels, values, colors }) => (
    <Line
        typeof="line"
        data={{
            labels,
            datasets: [
                {
                    data: values,
                    ...(colors || {
                        borderColor: '#36A2EB',
                        backgroundColor: '#9BD0F5',
                    }),
                },
            ],
        }}
        options={{
            plugins: {
                title: {
                    display: true,
                    text: title,
                },
                legend: {
                    display: false,
                },
            },
        }}
    />
)

export default Chart

import {
    Box,
    CircularProgress,
    CircularProgressProps,
    Typography,
} from '@mui/material'

const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number }
) => (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                /* eslint-disable-next-line react/destructuring-assignment */
            >{`${Math.round(props.value)}%`}</Typography>
        </Box>
    </Box>
)

export default CircularProgressWithLabel

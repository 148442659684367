import { daredropApiUrl } from 'constants/app'
import requestHandler from 'utils/requestHandler'
import { HTTP_STATUS_CODES } from 'utils/ajax'
import { z } from 'zod'
import { TokensService } from '../../services/tokens.service'
import { AuthService } from '../../services/auth.service'

export type ApiRequestBaseConfig = {
    payload: unknown
}

export type ApiRequestConfig<ResponseSchema> = ApiRequestBaseConfig & {
    responseSchema: z.ZodType<ResponseSchema>
    retries?: number
}

type ApiError = { message: string }
type ApiSuccessResponse<T> = { statusCode: 200; body: T }
type ApiFailResponse = { statusCode: number; error: ApiError }
type ApiResponse<T> = ApiSuccessResponse<T> | ApiFailResponse

const isSuccessResponse = <T>(
    response: unknown
): response is ApiSuccessResponse<T> =>
    typeof response === 'object' &&
    response !== null &&
    'statusCode' in response &&
    response.statusCode === HTTP_STATUS_CODES.OK

export const apiRequest = async <ResponseSchema>(
    config: ApiRequestConfig<ResponseSchema>
): Promise<ResponseSchema> => {
    const { responseSchema, payload, retries = 1 } = config

    const response = await requestHandler<ApiResponse<unknown>>({
        url: daredropApiUrl,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload,
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })

    if (!isSuccessResponse(response)) {
        if (response.statusCode === HTTP_STATUS_CODES.UNAUTHORIZED) {
            if (retries > 0) {
                await AuthService.revalidateAuth()
                return apiRequest({
                    ...config,
                    retries: retries - 1,
                })
            }
            await AuthService.logOut()
        }
        throw new Error(response.error.message)
    }

    return responseSchema.parse(response.body)
}

import { TextField, TextFieldProps } from '@material-ui/core'
import { FormContext } from 'components/Form'

type PercentageInputProps = {
    source: string
} & TextFieldProps

export const PercentageField = ({ source, ...props }: PercentageInputProps) => {
    return (
        <FormContext.Consumer>
            {({ form, handleFormChange }) => (
                <TextField
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                        handleFormChange(
                            source,
                            e.target.value
                                ? Number.parseFloat(e.target.value)
                                : undefined
                        )
                    }}
                    value={form[source]}
                    type="number"
                    inputMode="decimal"
                    InputProps={{
                        endAdornment: '%',
                        inputProps: {
                            max: 100,
                            min: 0,
                        },
                    }}
                    {...props}
                />
            )}
        </FormContext.Consumer>
    )
}

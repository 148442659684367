import { ComponentType } from 'react'
import { CronData } from 'utils/creator-api/models/response/getStackMetricsResponse'
import Chart from 'components/Chart'

const AWS_CONSOLE_LAMBDAS_URL =
    'https://us-east-1.console.aws.amazon.com/lambda/home?region=us-east-1#/functions/'

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: 20,
        paddingBottom: 10,
        borderBottom: '2px solid grey',
    },
    cronBox: {
        display: 'flex',
        border: '2px solid black',
        borderRadius: 5,
        padding: 10,
        width: '60%',
        justifyContent: 'space-between',
    },
    cronDetail: {
        marginRight: 20,
    },
    chartContainer: {
        width: '30%',
        maxHeight: 300,
    },
}

const hasErrors = (values: number[]): boolean => {
    return values.some((x) => x > 0)
}

const CloudwatchEvents: ComponentType<{ cronsData: CronData[] }> = ({
    cronsData,
}) => (
    <div style={styles.container}>
        <h2>Crons:</h2>
        {cronsData.map(
            ({
                name,
                scheduleExpression,
                targetFunctionName,
                targetErrorsMetric,
                targetDurationMetric,
            }) => (
                <div
                    key={name}
                    style={{
                        ...styles.cronBox,
                        borderColor: hasErrors(targetErrorsMetric.metricValues)
                            ? 'red'
                            : undefined,
                    }}
                >
                    <div style={styles.cronDetail}>
                        <p>Name: {name}</p>
                        <p>
                            Target:{' '}
                            <a
                                href={`${AWS_CONSOLE_LAMBDAS_URL}/${targetFunctionName}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {targetFunctionName}
                            </a>
                        </p>
                        <p>Expression: {scheduleExpression}</p>
                    </div>
                    <div style={styles.chartContainer}>
                        <Chart
                            title="Errors"
                            labels={targetErrorsMetric.formattedTimestamps}
                            values={targetErrorsMetric.metricValues}
                            colors={{
                                borderColor: '#FF0000',
                            }}
                        />
                    </div>
                    <div style={styles.chartContainer}>
                        <Chart
                            title="Duration"
                            labels={targetDurationMetric.formattedTimestamps}
                            values={targetDurationMetric.metricValues.map(
                                Math.trunc
                            )}
                        />
                    </div>
                </div>
            )
        )}
    </div>
)

export default CloudwatchEvents

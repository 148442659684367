import { daredropApiUrl, daredropUrl } from 'constants/app'
import requestHandler from 'utils/requestHandler'
import { TokensService } from '../services/tokens.service'

export default async (file: File, minutesTtl: number) => {
    const payload = {
        fileName: file.name,
        minutesTtl,
    }
    const { body } = await requestHandler<{
        body: {
            signedUrl: string
            contentType: string
            fileName: string
        }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'GET_UPLOAD_SIGNED_URL',
            payload,
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })
    const { signedUrl, contentType, fileName } = body

    await requestHandler({
        url: signedUrl,
        file,
        method: 'PUT',
        headers: {
            'Content-Type': contentType,
            ...(minutesTtl
                ? { 'x-amz-tagging': 'removeAfterExpiration=true' }
                : {}),
        },
    })
    return `${daredropUrl}/files/${fileName}`
}

import { ComponentType, useMemo, useState } from 'react'
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core'

type Variant = {
    id: string
    component: ComponentType
}

type MultiVariantInputProps = {
    variants: Variant[]
}

export const MultiVariantInput = ({ variants }: MultiVariantInputProps) => {
    const [selectedVariantId, setSelectedVariantId] = useState(variants[0].id)

    const VariantComponent = useMemo(() => {
        const variant = variants.find(({ id }) => id === selectedVariantId)

        if (!variant) {
            return () => null
        }

        return variant.component
    }, [selectedVariantId, variants])

    return (
        <div>
            <VariantComponent />
            <RadioGroup
                onChange={(event) => setSelectedVariantId(event.target.value)}
                name="variant"
                value={selectedVariantId}
            >
                {variants.map(({ id }) => (
                    <FormControlLabel
                        key={id}
                        value={id}
                        label={id}
                        control={<Radio />}
                    />
                ))}
            </RadioGroup>
        </div>
    )
}

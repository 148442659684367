import { ReactElement } from 'react'
import {
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import { FormContext } from 'components/Form'

export type RadioOptionValue = string | number | boolean

export type RadioOption = {
    value: RadioOptionValue
    label: string
}

export type RadioFieldProps = {
    options: RadioOption[]
    source: string
    onChange?: (form: Record<string, unknown>) => void
    label?: string
}

export const RadioField = ({
    options,
    label,
    source,
    onChange,
}: RadioFieldProps): ReactElement => (
    <FormContext.Consumer>
        {({ form, handleFormChange }) => (
            <div>
                <FormLabel>{label}</FormLabel>
                <RadioGroup>
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.value.toString()}
                            value={option.value}
                            control={
                                <Radio
                                    onChange={() => {
                                        if (onChange) {
                                            onChange(form)
                                        }
                                        handleFormChange(source, option.value)
                                    }}
                                    checked={form[source] === option.value}
                                />
                            }
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            </div>
        )}
    </FormContext.Consumer>
)

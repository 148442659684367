import { Product, ProductType } from 'types'

export class ProductResolver {
    public readonly product: Product

    constructor(product: Product) {
        this.product = product
    }

    getId() {
        return this.product.id
    }

    getName() {
        switch (this.product.type) {
            case ProductType.Key: {
                const region = this.product.id.split('|').at(2)
                return `${this.product.name} (${region})`
            }
            default:
                return this.product.name
        }
    }
}

import { ComponentType } from 'react'
import moment from 'moment-mini'
import TextField from '@mui/material/TextField'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import Form, { FormContext } from 'components/Form'
import FieldNumeric from 'components/FieldNumeric'
import FieldText from 'components/FieldText'
import FilterAutocomplete from 'components/FilterAutocomplete'
import FilterTwitchGamesAutocomplete from 'components/FilterTwitchGamesAutocomplete'
import FilterTwitchUsersAutocomplete from 'components/FilterTwitchUsersAutocomplete'
import { createStream, CreateStreamPayload } from 'utils/creatorApi'
import languageOptions from 'utils/languageOptions'
import { FilterOption } from 'types'

type addStreamFormData = {
    title: string
    averageViewers: number
    durationMinutes: number
    game: FilterOption
    language: FilterOption
    streamer: FilterOption
    endedAt: string
    startedAt: string
}

const style = {
    width: 280,
    margin: '0 auto',
    display: 'grid',
    gridGap: 20,
}

const onSubmit = async (formData: addStreamFormData) => {
    const {
        title,
        averageViewers,
        durationMinutes,
        language,
        game,
        streamer,
        endedAt,
        startedAt,
    } = formData
    const { value: gameId, label: gameName } = game

    const payload: CreateStreamPayload = {
        title,
        averageViewers,
        durationMinutes,
        language: language.value,
        gameId,
        gameName,
        endedAt,
        startedAt,
        userId: streamer.value,
    }

    return createStream(payload)
}

const GameField = () => (
    <FilterTwitchGamesAutocomplete
        label="Game"
        source="game"
        multiple={false}
    />
)
const LanguageField = () => (
    <FilterAutocomplete
        label="Languages"
        source="language"
        options={languageOptions}
        multiple={false}
    />
)

const DurationFields = () => {
    const sourceDurationMinutes = 'durationMinutes'
    const sourceFrom = 'startedAt'
    const sourceTo = 'endedAt'
    return (
        <FormContext.Consumer>
            {({ form, handleMultipleFormChanges }) => (
                <>
                    <TextField
                        style={{ width: 130 }}
                        type="number"
                        variant="outlined"
                        value={form[sourceDurationMinutes] || 0}
                        label="Duration (minutes)"
                        onChange={(e) => {
                            const newDurationMinutes = Number(
                                e.target.value || 0
                            )
                            if (newDurationMinutes < 0) return
                            handleMultipleFormChanges({
                                [sourceDurationMinutes]: newDurationMinutes,
                                ...(form[sourceFrom]
                                    ? {
                                          [sourceTo]: moment(form[sourceFrom])
                                              .add(
                                                  newDurationMinutes,
                                                  'minutes'
                                              )
                                              .format(),
                                      }
                                    : {}),
                                ...(form[sourceTo] && !form[sourceFrom]
                                    ? {
                                          [sourceFrom]: moment(form[sourceTo])
                                              .subtract(
                                                  newDurationMinutes,
                                                  'minutes'
                                              )
                                              .utc(),
                                      }
                                    : {}),
                            })
                        }}
                    />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            label="Started at"
                            value={form[sourceFrom] || null}
                            maxDateTime={
                                form[sourceTo]
                                    ? moment(form[sourceTo])
                                    : undefined
                            }
                            onChange={() => undefined}
                            onAccept={(value) => {
                                if (!value) return
                                handleMultipleFormChanges({
                                    [sourceFrom]: value.utc().format(),
                                    ...(form[sourceDurationMinutes]
                                        ? {
                                              [sourceTo]: value
                                                  .utc()
                                                  .add(
                                                      form[
                                                          sourceDurationMinutes
                                                      ],
                                                      'minutes'
                                                  )
                                                  .format(),
                                          }
                                        : {}),
                                    ...(form[sourceTo] &&
                                    !form[sourceDurationMinutes]
                                        ? {
                                              [sourceDurationMinutes]: moment
                                                  .duration(
                                                      value.diff(
                                                          moment(form[sourceTo])
                                                      )
                                                  )
                                                  .asMinutes(),
                                          }
                                        : {}),
                                })
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DateTimePicker
                            label="Ended at"
                            value={form[sourceTo] || null}
                            onChange={() => undefined}
                            onAccept={(value) => {
                                if (!value) return
                                handleMultipleFormChanges({
                                    [sourceTo]: value.utc().format(),
                                    ...(form[sourceDurationMinutes]
                                        ? {
                                              [sourceFrom]: value
                                                  .utc()
                                                  .subtract(
                                                      form[
                                                          sourceDurationMinutes
                                                      ],
                                                      'minutes'
                                                  )
                                                  .format(),
                                          }
                                        : {}),
                                    ...(form[sourceFrom] &&
                                    !form[sourceDurationMinutes]
                                        ? {
                                              [sourceDurationMinutes]: moment
                                                  .duration(
                                                      moment(value).diff(
                                                          form[sourceFrom]
                                                      )
                                                  )
                                                  .asMinutes(),
                                          }
                                        : {}),
                                })
                            }}
                            minDateTime={
                                form[sourceFrom]
                                    ? moment(form[sourceFrom])
                                    : undefined
                            }
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </>
            )}
        </FormContext.Consumer>
    )
}

const AddStream: ComponentType = () => (
    <Form
        style={style}
        submits={[{ onSubmit }]}
        defaults={{
            language: languageOptions.find(({ value }) => value === 'en'),
            averageViewers: 0,
        }}
    >
        <FilterTwitchUsersAutocomplete
            label="Streamer"
            source="streamer"
            multiple={false}
        />
        <FieldText source="title" label="Title" />
        <FieldNumeric source="averageViewers" label="CCV" />
        <GameField />
        <LanguageField />
        <DurationFields />
    </Form>
)

export default AddStream

type AuthApiError = {
    error: string
}

const getErrorMessage = (err: unknown) => {
    if (typeof err === 'string') {
        return err
    }

    if (typeof err === 'object' && err !== null && 'error' in err) {
        return (err as AuthApiError).error
    }

    if (err instanceof Error) {
        return err.message
    }

    return 'Something went wrong, check console'
}

const getCurrentQueryValue = () => {
    const { searchParams } = new URL(window.location.href)
    return searchParams.get('value')
}

// eslint-disable-next-line import/prefer-default-export
export { getErrorMessage, getCurrentQueryValue }

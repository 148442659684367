// @ts-nocheck
/* eslint-disable */
import ajax, { AjaxPayload } from 'utils/ajax'
import { notify } from 'components/LayoutWithNotify'

type ResponseBase = {
    statusCode: number
    error?: { message: string }
}

export default async <T>(payload: AjaxPayload): Promise<T & ResponseBase> => {
    let res
    try {
        res = await ajax<T & ResponseBase>(payload)
        if (res.error){
            throw new Error(res.error)
        }
        return res
    } catch (e) {
        let errorMessage
        if (e instanceof Error) {
            errorMessage = e.message
        }
        if (
            typeof e === 'object' &&
            e !== null && // typeof null is 'object' xD
            'error' in e &&
            typeof e.error === 'string'
        ) {
            errorMessage = e.error
        }
        if (res?.error?.message) {
            errorMessage = res.error.message
        }

        if (!errorMessage) {
            errorMessage = 'Unknown error'
        }

        if(typeof errorMessage === 'object') {
            errorMessage = JSON.stringify(errorMessage)
        }

        if (notify) {
            notify(errorMessage, { type: 'error' })
        }
        throw errorMessage
    }
}

import { resourcesIds } from 'resources'
import { getListKey, editListItemsIdKey } from 'keys'
import { Platform } from 'types'

export type SignedUpCreator = {
    id: string
    mnemonicId: string
    platform: Platform
    projectCreationDate?: string
    email: string
    url: string
}

export default {
    [resourcesIds.SIGNED_UP_CREATORS_RESOURCE_ID]: {
        [getListKey]: (data: {
            pagination: { perPage: number; page: number }
            filter: { value: string; filter: string }
        }) => {
            const [filter] = Object.entries(data.filter || {})
            return {
                endpointId: 'GET_SIGNED_UP_CREATORS',
                payload: {
                    filterName: filter?.at(0),
                    filterValue: filter?.at(1),
                },
            }
        },
        [editListItemsIdKey]: (rawData: SignedUpCreator[]) => rawData,
    },
}

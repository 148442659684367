import { resourcesIds } from '../resources'
import { editListItemsIdKey, getListKey } from '../keys'

export const transactionTypesMap = {
    adminPointsModification: 'adminPointsModification',
    paypalPointsPayoutPending: 'paypalPointsPayoutPending',
    paypalPointsPayoutDone: 'paypalPointsPayoutDone',
    paidOffPlatform: 'paidOffPlatform',
    pointsAward: 'pointsAward',
} as const

export type TransactionType =
    (typeof transactionTypesMap)[keyof typeof transactionTypesMap]

export const readableTransactionsTypes: Record<TransactionType, string> = {
    adminPointsModification: 'Added points by admin',
    pointsAward: 'Gained points from campaign',
    paypalPointsPayoutDone: 'Paid off',
    paypalPointsPayoutPending: 'Queued',
    paidOffPlatform: 'Paid off-platform',
}

export type Transaction = {
    id: string
    value: number
    recipientId: string
    recipientDisplayName: string
    transactionType: TransactionType
    created: string
}

export type PointsDetails = {
    clicks: number
    displayName: string
    dropEventId: string
    id: string
    paidOffPlatform: boolean
    paypalEmail: string
    pointsAwarded: number
    userId: string
    value: number
    viewerHours: number
}

export default {
    [resourcesIds.POINTS_TRANSACTIONS_RESOURCE_ID]: {
        [getListKey]: ({
            filter,
        }: {
            filter: { transactionType: TransactionType[]; recipient: string }
        }) => ({
            endpointId: 'GET_TRANSACTIONS',
            payload: {
                transactionTypes: filter.transactionType,
                recipient: filter.recipient,
            },
        }),
        [editListItemsIdKey]: (transactions: Transaction[]) => transactions,
    },
}

import { ReactElement } from 'react'
import AsyncFilterAutocomplete from 'components/AsyncFilterAutocomplete'
import { DaredropApi } from 'utils/daredrop-api'

const FilterTwitchGamesAutocomplete = ({
    source,
    label,
    multiple,
}: {
    source: string
    label?: string
    multiple?: boolean
}): ReactElement => (
    <AsyncFilterAutocomplete
        source={source}
        asyncOptionsFn={DaredropApi.searchTwitchGames}
        label={label}
        multiple={multiple}
    />
)
export default FilterTwitchGamesAutocomplete

import { SelectInput, useRecordContext } from 'react-admin'
import {
    readableStates,
    Verification,
    verificationStates,
} from 'resourceDescriptions/verifications'
import { RaRecord } from 'types'

type SelectStateInput = {
    source: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SelectStateInput = (props: SelectStateInput) => {
    const record = useRecordContext<RaRecord<Verification>>()
    const [, recordState] = record.sk.split('|')

    return (
        <SelectInput
            label="State"
            choices={verificationStates.map((state) => ({
                id: state,
                name: readableStates[state],
            }))}
            defaultValue={recordState}
            {...props}
        />
    )
}

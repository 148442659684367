import React, { ComponentType } from 'react'
import { Link } from 'react-router-dom'
import { List, Datagrid, TextField, Button, FunctionField } from 'react-admin'
import { resourcesIds } from '../../resources'

export const CampaignTermsList: ComponentType = () => {
    return (
        <List
            disableSyncWithLocation
            empty={false}
            title="Campaign Terms List"
            hasEdit
            resource={resourcesIds.CAMPAIGN_TERMS}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField label="Creator user ID" source="creatorId" />
                <TextField label="Created" source="created" />
                <TextField
                    label="Estimated points to earn"
                    source="estimatedPointsToEarn"
                />
                <TextField
                    label="Reserved Points Total"
                    source="reservedPointsTotal"
                />
                <TextField
                    label="Reserved Points Left"
                    source="reservedPointsLeft"
                />
                <TextField
                    label="Reservation due date"
                    source="pointsReservationDueDate"
                />
                <FunctionField
                    render={(record: { dropId: string; creatorId: string }) => {
                        const { dropId, creatorId } = record

                        return (
                            <Button
                                label="Edit"
                                component={Link}
                                style={{ textDecoration: 'none' }}
                                to={`/${resourcesIds.CAMPAIGN_TERMS}/${dropId}/${creatorId}`}
                                title="Edit"
                            />
                        )
                    }}
                />
            </Datagrid>
        </List>
    )
}

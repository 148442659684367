import { resourcesIds } from 'resources'
import {
    getListKey,
    editListItemsIdKey,
    deleteKey,
    getOneKey,
    editOneItemIdKey,
} from 'keys'
import { getCurrentQueryValue } from '../utils/common'

export default {
    [resourcesIds.CAMPAIGN_STREAMS_RESOURCE_ID]: {
        [getListKey]: () => {
            const queryValue = getCurrentQueryValue()

            if (!queryValue) {
                return undefined
            }

            return {
                endpointId: 'GET_CAMPAIGN_STREAMS',
                payload: { campaignId: queryValue },
            }
        },
        [getOneKey]: ({ id }: { id: string }) => ({
            endpointId: 'GET_CAMPAIGN_STREAMS',
            payload: { campaignId: id },
        }),
        [editOneItemIdKey]: (data: unknown) => data || [],
        [editListItemsIdKey]: (data: unknown) => data,
        [deleteKey]: (params: Record<string, any>) => {
            const { id } = params
            return {
                endpointId: 'CREATOR_API_PROXY',
                payload: {
                    body: { id },
                    url: `test-streams`,
                    method: 'DELETE',
                },
            }
        },
    },
}

import { ReactElement } from 'react'
import { FormLabel, TextField } from '@material-ui/core'
import { FormContext } from 'components/Form'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { TextFieldProps } from '@mui/material/TextField'
import moment from 'moment-mini'

const DateRangePicker = ({
    label,
    sourceFrom,
    sourceTo,
}: {
    label?: string
    sourceFrom: string
    sourceTo: string
}): ReactElement => (
    <FormContext.Consumer>
        {({ form, handleFormChange }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormLabel style={{ textAlign: 'center' }}>{label}</FormLabel>
                <FormLabel>From</FormLabel>
                <DatePicker
                    value={form[sourceFrom]}
                    onChange={(value) => {
                        if (!value) return
                        handleFormChange(
                            sourceFrom,
                            value
                                .utc()
                                .add(moment().utcOffset(), 'minutes')
                                .startOf('day')
                                .format()
                        )
                    }}
                    renderInput={(params: TextFieldProps) => (
                        // @ts-ignore -- we need to do that probably due to versions incompatibility
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                disabled: true,
                                value: form[sourceFrom]
                                    ? moment
                                          .utc(form[sourceFrom])
                                          .format('MM/DD/YYYY')
                                    : params.inputProps?.placeholder,
                            }}
                        />
                    )}
                />
                <FormLabel>To</FormLabel>
                <DatePicker
                    value={form[sourceTo]}
                    onChange={(value) => {
                        if (!value) return
                        handleFormChange(
                            sourceTo,
                            value
                                .utc()
                                .add(moment().utcOffset(), 'minutes')
                                .endOf('day')
                                .format()
                        )
                    }}
                    renderInput={(params: TextFieldProps) => (
                        // @ts-ignore -- we need to do that probably due to versions incompatibility
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                disabled: true,
                                value: form[sourceTo]
                                    ? moment
                                          .utc(form[sourceTo])
                                          .format('MM/DD/YYYY')
                                    : params.inputProps?.placeholder,
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        )}
    </FormContext.Consumer>
)

export default DateRangePicker

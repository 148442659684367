import { resourcesIds } from 'resources'
import { getOneKey, editOneItemIdKey } from 'keys'

export const predictWatchTime = {
    [resourcesIds.PREDICT_WATCH_TIME]: {
        [getOneKey]: ({ id }: { id: string }): Record<string, any> => {
            return {
                endpointId: 'GET_DROP_WATCH_TIME_PREDICTION',
                payload: {
                    dropId: id,
                },
            }
        },
        [editOneItemIdKey]: (
            rawData: Record<string, any>[]
        ): Record<string, any> => {
            return rawData
        },
    },
}

import React from 'react'
import {
    Create,
    minValue,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin'
import { useHelperText } from 'hooks/useHelperText'
import { pointsToDollarsHandler } from 'utils/pointsToDollars'
import { transactionTypesMap } from 'resourceDescriptions/points-transactions'

const pointsTransactionsChoices = [
    {
        name: 'Admin points modification',
        id: transactionTypesMap.adminPointsModification,
    },
    {
        name: 'Paid off-platform transaction',
        id: transactionTypesMap.paidOffPlatform,
    },
]

const CreatePointsForm = () => {
    const { helperText, setHelperText } = useHelperText(pointsToDollarsHandler)

    return (
        <SimpleForm>
            <SelectInput
                label="Type of transaction"
                source="transactionType"
                defaultValue="adminPointsTransaction"
                choices={pointsTransactionsChoices}
                validate={required()}
            />
            <TextInput source="userId" label="User Id" />
            <NumberInput
                label="Points"
                source="modificationValue"
                onChange={setHelperText}
                helperText={helperText}
                validate={minValue(1)}
                format={(val) => Math.floor(val)}
                defaultValue={0}
            />
        </SimpleForm>
    )
}

const CreatePointsForCreator = () => {
    return (
        <Create redirect={false}>
            <CreatePointsForm />
        </Create>
    )
}

export default CreatePointsForCreator

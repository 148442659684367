import split from 'ramda/src/split'
import pipe from 'ramda/src/pipe'
import filter from 'ramda/src/filter'
import isEmpty from 'ramda/src/isEmpty'
import { useState, ChangeEvent, Dispatch, SetStateAction } from 'react'
import { Button } from 'react-admin'
import { Input } from '@mui/material'
import { uploadCreators } from 'utils/creatorApi'

export const UploadCreatorListModal = ({
    setModalOpened,
}: {
    setModalOpened: Dispatch<SetStateAction<boolean>>
}) => {
    const [file, setFile] = useState<File | null>(null)
    const submitFn = (input: File | null) => {
        if (!input) return
        const reader = new FileReader()
        reader.onload = async (e) => {
            if (e.target) {
                const usernames = pipe(
                    split(/\r\n|\r|\n/),
                    filter((record) => !isEmpty(record))
                )(e.target.result as string)

                await uploadCreators(usernames)
            }
        }
        reader.readAsText(input)
        setModalOpened(false)
    }
    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                minWidth: 400,
                height: 100,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                background: 'white',
                border: '2px solid #000',
            }}
        >
            <Input
                onChange={(e: ChangeEvent) => {
                    const target = e.target as HTMLInputElement
                    if (target.files) {
                        setFile(target.files[0])
                    }
                }}
                inputProps={{ accept: '.csv' }}
                type="file"
            />
            <Button onClick={() => submitFn(file)} label="Submit" />
        </div>
    )
}

type StackConfig = {
    AWS_DEV_EMAIL: string
    AWS_STACK_DOMAIN: string
    AWS_ACCOUNT_ID: string
    AWS_REGION: string
}

const stackConfig: StackConfig = {} as StackConfig

export default stackConfig

import { Show, useShowController } from 'react-admin'
import { Card, Container, Tooltip } from '@mui/material'
import { resourcesIds } from 'resources'
import { RaRecord } from 'types'
import { GetPointsStatisticResponseBody } from 'resourceDescriptions/points-statistics'
import { useMemo } from 'react'
import CircularProgressWithLabel from 'components/CircularProgressWithLabel'

type PointsStatCard = {
    label: string
    value?: number
}

const PointsStatCard = ({ label, value }: PointsStatCard) => (
    <Card
        sx={{
            width: '50%',
            padding: 4,
            margin: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        }}
    >
        <h3>{label}</h3>
        <p style={{ fontSize: 42, fontWeight: 'bold' }}>
            {new Intl.NumberFormat('en-US').format(value || 0)}
        </p>
    </Card>
)

const PointsStatistics = () => {
    const { record } = useShowController<
        RaRecord<GetPointsStatisticResponseBody>
    >({
        resource: resourcesIds.POINTS_STATISTICS,
    })

    const pointsToPayoutOfAllPercentage = useMemo(() => {
        if (!record || !record.points) {
            return 100
        }
        return (record.pointsQueuedToPayout / record.points) * 100
    }, [record])

    return (
        <Show
            title="Points statistics"
            resource={resourcesIds.POINTS_STATISTICS}
        >
            <h1 style={{ textAlign: 'center' }}>Points statistics</h1>
            <Container sx={{ display: 'flex' }}>
                <PointsStatCard label="All points" value={record?.points} />
                <PointsStatCard
                    label="Points queued to payout"
                    value={record?.pointsQueuedToPayout}
                />
            </Container>
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Tooltip title="Pending points payouts divided by all points">
                    <h2
                        style={{
                            textAlign: 'center',
                            textDecoration: 'dotted',
                            borderBottom: '1px dotted #000',
                        }}
                    >
                        Percentage of payout points
                    </h2>
                </Tooltip>
                <CircularProgressWithLabel
                    sx={{
                        margin: 2,
                    }}
                    variant="determinate"
                    value={pointsToPayoutOfAllPercentage}
                    size={72}
                />
            </Container>
        </Show>
    )
}

export default PointsStatistics

import ajax from '../../utils/ajax'
import { authApiUrl } from '../../constants/app'
import {
    AuthApi_RefreshTokenResponse,
    AuthApi_SignInResponse,
    AuthApi_Credentials,
} from './auth-api.types'
import { LockMethod } from '../../decorators/lock.decorator'

export class AuthApiService {
    @LockMethod()
    static async refreshToken(token: string) {
        return ajax<AuthApi_RefreshTokenResponse>({
            url: `${authApiUrl}/refresh-token`,
            method: 'POST',
            body: {
                refreshToken: token,
            },
            headers: {},
        })
    }

    static async signIn(credentials: AuthApi_Credentials) {
        return ajax<AuthApi_SignInResponse>({
            url: `${authApiUrl}/sign-in`,
            method: 'POST',
            body: credentials,
            headers: {},
        })
    }
}

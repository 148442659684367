import { useState, ComponentType } from 'react'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment-mini'
import { GetMetricsFilters } from 'types'
import { CreatorApi } from 'utils/creator-api'
import Filters from './StacksMetricsFilter'
import Metrics from './StackMetrics'

const StacksMetrics: ComponentType = () => {
    const [filters, setFilters] = useState<GetMetricsFilters>({
        startAt: moment().utc().subtract(3, 'days').format(),
        endAt: moment().utc().format(),
    })

    const { startAt, endAt } = filters

    const {
        data: metricsData,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['metrics'],
        queryFn: () => CreatorApi.getMetrics({ startAt, endAt }),
    })

    if (isLoading) {
        return <span>Loading...</span>
    }

    if (isError) {
        return <span>Error: Something went wrong...</span>
    }

    return (
        <>
            <Filters {...{ filters, setFilters }} />
            <Metrics metrics={metricsData} />
        </>
    )
}
export default StacksMetrics

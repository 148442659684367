export const resourcesIds = {
    CREATORS_REPORT: 'creators-report',
    CREATORS_REPORT_ALT: 'creators-report-alt',
    STREAMS_STATS_REPORT: 'streams-stats-report',
    GAME_STATS_REPORT: 'game-stats-report',
    SIGNED_UP_CREATORS_RESOURCE_ID: 'signed-up-creators-report',
    USERS_LIST_RESOURCE_ID: 'users-list',
    POINTS_MARKETPLACE_RESOURCE_ID: 'points-marketplace',
    CAMPAIGNS_RESOURCE_ID: 'campaigns',
    CAMPAIGN_POINTS_DETAILS: 'campaign-points-details',
    CAMPAIGN_STREAMS_RESOURCE_ID: 'campaign-streams',
    POINTS_TRANSACTIONS_RESOURCE_ID: 'points-transactions',
    CAMPAIGN_TRANSACTIONS_RESOURCE_ID: 'campaign-transactions',
    POINTS_STATISTICS: 'points-statistics',
    REWARDS_RESOURCE_ID: 'rewards',
    MANAGE_CAMPAIGN: 'manage-campaign',
    PREDICT_WATCH_TIME: 'predict-watch-time',
    POINTS_APPLICATION_LIST: 'points-application-list',
    STACKS_METRICS: 'stacks-metrics',
    PREVIOUS_DARES_RESOURCE_ID: 'previous-dares',
    CREATORS_REPORT_YOUTUBE: 'creators-report-youtube',
    CREATORS_REPORT_YOUTUBE_V2: 'creators-report-youtube-v2',
    CREATORS_REPORT_TIKTOK: 'creators-report-tiktok',
    VERIFICATIONS: 'verifications',
    CAMPAIGN_SURVEY: 'campaign-survey',
    CAMPAIGN_TERMS: 'campaign-terms',
} as const

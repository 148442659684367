import {
    Layout,
    useNotify,
    NotificationType,
    NotificationOptions,
    CoreLayoutProps,
} from 'react-admin'
import Menu from 'components/Menu'

type Notify = (
    message: string,
    options?: NotificationOptions & { type?: NotificationType }
) => void

// exporting react hook result, so it can be accessed outside of the React components
// eslint-disable-next-line import/no-mutable-exports
export let notify: undefined | Notify

const LayoutWithErrror = (props: CoreLayoutProps) => {
    notify = useNotify()
    return <Layout {...props} menu={Menu} />
}

export default LayoutWithErrror

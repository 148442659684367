import { ReactNode } from 'react'
import { useDialogContext } from 'contexts/dialog'

type DialogBaseProps = {
    children: ReactNode
}

export const DialogBase = ({ children }: DialogBaseProps) => {
    const { isOpened, setIsOpened } = useDialogContext()

    if (!isOpened) {
        return null
    }

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                    zIndex: 999,
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    backdropFilter: 'blur(10px)',
                }}
                onClick={() => setIsOpened(false)}
            />
            <div
                style={{
                    position: 'fixed',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    borderRadius: 4,
                    filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.5))',
                }}
            >
                {children}
            </div>
        </>
    )
}

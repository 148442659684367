import { useQuery, useMutation } from '@tanstack/react-query'
import { FilterOption } from 'types'
import { CreatorApi, TiktokReportRequestBody } from 'utils/creator-api'

export type CreatorsTiktokReportFormData = {
    topics: FilterOption[]
    excludedTopics: FilterOption[]
    regions: FilterOption[]
    residentCountries: FilterOption[]
    brandName: string
    followersMin: number
    followersMax: number
    engagementRateMin: number
    engagementRateMax: number
    hasEmail: boolean
    receiveInvitationsSettingOn: boolean
    audienceAgeScope: string
    audienceGenderScope: string
    audienceGenderMinDecimal: number
}

const filterOptionsToPlain = (inputArray?: FilterOption[]) => {
    if (!inputArray || inputArray.length === 0) {
        return
    }
    // eslint-disable-next-line consistent-return
    return inputArray.map(({ value }) => value)
}

const formDataToRequestPayload = ({
    topics,
    excludedTopics,
    regions,
    residentCountries,
    brandName,
    followersMin,
    followersMax,
    engagementRateMin,
    engagementRateMax,
    hasEmail,
    receiveInvitationsSettingOn,
    audienceAgeScope,
    audienceGenderScope,
    audienceGenderMinDecimal,
}: CreatorsTiktokReportFormData): TiktokReportRequestBody => ({
    topics: filterOptionsToPlain(topics),
    excludedTopics: filterOptionsToPlain(excludedTopics),
    regions: filterOptionsToPlain(regions),
    residentCountries: filterOptionsToPlain(residentCountries),
    brandName,
    followersMin,
    followersMax,
    engagementRateMin,
    engagementRateMax,
    hasEmail,
    receiveInvitationsSettingOn,
    audienceAgeScope,
    audienceGenderScope,
    audienceGenderMinDecimal,
})

export const useCreatorsReportTiktokForm = () => {
    const { mutateAsync } = useMutation({
        mutationFn: async (data: CreatorsTiktokReportFormData) => {
            const payload = formDataToRequestPayload(data)
            return CreatorApi.requestCreatorsTiktokReport(payload)
        },
    })

    const handleSubmit = (data: CreatorsTiktokReportFormData) => {
        mutateAsync(data)
    }

    const { data: filterOptions, isLoading } = useQuery(
        ['filterOptions'],
        CreatorApi.getTiktokFilters,
        // fetch filters only once
        {
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    )

    return { handleSubmit, filterOptions, formLoading: isLoading }
}

/* eslint-disable import/prefer-default-export */
import always from 'ramda/src/always'
import tryCatch from 'ramda/src/tryCatch'

export const camelCaseSpace = tryCatch(
    (string) =>
        string
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str: string) => str.toUpperCase()),
    always(undefined)
)

import {
    List,
    Datagrid,
    TextField,
    SelectArrayInput,
    TextInput,
    useRecordContext,
    FunctionField,
} from 'react-admin'
import {
    readableTransactionsTypes,
    Transaction,
    transactionTypesMap,
} from 'resourceDescriptions/points-transactions'
import PaidOffPlatformButton from 'components/PaidOffPlatformButton'

const filters = [
    <TextInput key="recipient" alwaysOn source="recipient" />,
    <SelectArrayInput
        alwaysOn
        key="transactionType"
        source="transactionType"
        choices={Object.values(transactionTypesMap).map((transactionType) => ({
            id: transactionType,
            name: readableTransactionsTypes[transactionType],
        }))}
    />,
]

const transactionTypesAllowedToPaidOffPlatform: Transaction['transactionType'][] =
    [transactionTypesMap.paypalPointsPayoutPending]

const MarkAsPaidOffPlatformButton = () => {
    const record = useRecordContext<Transaction>()
    if (
        !transactionTypesAllowedToPaidOffPlatform.includes(
            record.transactionType
        )
    ) {
        return null
    }

    return <PaidOffPlatformButton />
}

const PointsTransactionsList = () => (
    <List
        title="Points transactions"
        filters={filters}
        filterDefaultValues={{
            transactionType: [
                transactionTypesMap.paypalPointsPayoutPending,
                transactionTypesMap.paypalPointsPayoutDone,
            ],
        }}
    >
        <Datagrid isRowSelectable={() => false}>
            <TextField
                source="recipientDisplayName"
                label="Recipient (Username)"
            />
            <TextField source="value" label="Points" />
            <FunctionField
                source="transactionType"
                label="Payment status"
                render={(record: Transaction) =>
                    readableTransactionsTypes[record.transactionType]
                }
            />
            <TextField source="created" label="Created" />
            <MarkAsPaidOffPlatformButton />
        </Datagrid>
    </List>
)

export default PointsTransactionsList

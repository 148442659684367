import { ReactElement } from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
import { FormContext } from './Form'

const FieldNumeric = ({
    source,
    modifier = (x) => x,
    reverseModifier = (x) => x,
    label,
    step,
}: {
    modifier?: (val: number) => number
    reverseModifier?: (val: number) => number
    label?: string
    source: string
    step?: number
}): ReactElement => (
    <FormContext.Consumer>
        {({ form, handleFormChange }) => (
            <MuiTextField
                label={label}
                type="number"
                inputProps={{
                    step,
                }}
                variant="outlined"
                value={modifier(form[source] || 0).toFixed(0)}
                onChange={(e) => {
                    handleFormChange(
                        source,
                        reverseModifier(Number(e.target.value))
                    )
                }}
            />
        )}
    </FormContext.Consumer>
)

export default FieldNumeric

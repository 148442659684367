import {
    afterUpdateActionKey,
    createKey,
    deleteKey,
    deleteManyKey,
    editListItemsIdKey,
    editOneItemIdKey,
    filterListKey,
    getListKey,
    getOneKey,
    updateKey,
    updateManyKey,
} from 'keys'
import isEmpty from 'ramda/src/isEmpty'
import splitEvery from 'ramda/src/splitEvery'
import { DataProvider, GetListParams } from 'react-admin'
import getResourceData from 'utils/getResourceData'
import { z } from 'zod'
import daredropApiRequest, {
    DareDropApiRequestBaseConfig,
} from 'utils/daredrop-api/apiRequest'
import { RaRecord } from './types'

type CustomPaginationMap = Record<
    string,
    {
        pages: Record<number, unknown[]>
        startKey?: string
        total?: number
        perPage: number
    }
>

const customPagination: CustomPaginationMap = {}

const isEmptyObj = (obj: Record<string, unknown>) =>
    !Object.values(obj).filter((x) => x).length

const getPaginationTotal = (resource: string) =>
    customPagination[resource].total

const getPaginatedData = (resource: string, page: number) =>
    customPagination[resource].pages[page - 1] || []

const getSortingResult = (order: string) => (order === 'ASC' ? -1 : 1)

const getSortedData = (data: unknown[], field: any, sortingResult: number) =>
    [...data].sort((a: any, b: any) =>
        a[field] > b[field] ? sortingResult : -sortingResult
    )

const getFilteredData = async (
    dataMod: unknown[],
    resource: string,
    filter: Record<string, string>
) => {
    if (isEmpty(filter)) return dataMod
    const { dataFiltered } = await getResourceData(resource, filterListKey, {
        data: dataMod,
        filterParams: filter,
    })
    return dataFiltered || dataMod
}

const handlePagination = (
    page: number,
    perPage: number,
    filter: Record<string, unknown>,
    sort: GetListParams['sort'],
    resource: string
) => {
    const currentResource = customPagination[resource]
    const currentPageData = currentResource?.pages[page - 1]

    if (!currentPageData) {
        return undefined
    }

    if (currentResource.perPage !== perPage || !isEmptyObj(filter)) {
        delete customPagination[resource]
        return undefined
    }

    return {
        data: getSortedData(
            currentPageData,
            sort.field,
            getSortingResult(sort.order)
        ),
        total: getPaginationTotal(resource),
    }
}
// isn't that too wide? we are losing types, shouldn't we use hooks inside components?
export default {
    getList: async (resource, params) => {
        const urlPath = window.location.pathname.split('/')
        const resourcePaginated = customPagination[resource]
        const resourceListPayload = await getResourceData(
            resource,
            getListKey,
            {
                ...params,
                customPagination: resourcePaginated,
                urlPath,
            }
        )
        const { pagination, ...requestBody } = resourceListPayload

        const {
            sort: { field, order },
            pagination: { perPage, page },
            filter,
        } = params

        if (pagination && (resourcePaginated || !resourceListPayload)) {
            const paginatedData = handlePagination(
                page,
                perPage,
                filter,
                { order, field },
                resource
            )

            if (paginatedData) {
                return paginatedData
            }
        }

        if (!resourceListPayload) {
            return { data: [], total: 0 }
        }

        const response = await daredropApiRequest({
            ...(requestBody as DareDropApiRequestBaseConfig),
            schema: z.union([
                z.object({
                    items: z.array(z.unknown()),
                    startKey: z.string().optional(),
                    total: z.number().optional(),
                }),
                z.array(z.unknown()),
            ]),
        })

        const dataRaw = Array.isArray(response) ? response : response.items
        const dataMod = (await getResourceData(
            resource,
            editListItemsIdKey,
            dataRaw
        )) as unknown[]

        const dataFiltered = await getFilteredData(dataMod, resource, filter)

        const dataSorted = getSortedData(
            dataFiltered,
            field,
            getSortingResult(order)
        )
        const dataPaginated = splitEvery(perPage, dataSorted)[page - 1]

        if (pagination) {
            customPagination[resource] = {
                pages: {
                    ...(customPagination[resource]?.pages || {}),
                    ...(dataSorted.length
                        ? {
                              [page - 1]: dataSorted,
                          }
                        : {}),
                },
                total: Array.isArray(response)
                    ? response.length
                    : response?.total || response?.items?.length,
                perPage,
            }
        }
        return pagination && customPagination[resource]
            ? {
                  data: getPaginatedData(resource, page),
                  total: getPaginationTotal(resource),
              }
            : { data: dataPaginated || [], total: dataFiltered.length }
    },
    getOne: async (resource, params) => {
        const urlPath = window.location.pathname.split('/')
        const { id, meta } = params
        const requestBody = (await getResourceData(
            resource,
            getOneKey,
            { urlPath, id },
            meta
        )) as DareDropApiRequestBaseConfig

        const res = await daredropApiRequest({
            ...requestBody,
            schema: z.unknown(),
        })

        const data = await getResourceData(resource, editOneItemIdKey, res)

        if (!data.id) {
            return {
                data: { ...data, id: id || 0 },
            }
        }
        return { data }
    },
    create: async (resource, params): Promise<Record<string, any>> => {
        const { data } = params
        const requestBody = (await getResourceData(
            resource,
            createKey,
            data
        )) as DareDropApiRequestBaseConfig

        const res = await daredropApiRequest({
            ...requestBody,
            schema: z.unknown(),
        })

        return { data: await getResourceData(resource, editOneItemIdKey, res) }
    },
    createMany: async (resource, params): Promise<Record<string, any>> => {
        const { data } = params
        const requestBody = (await getResourceData(
            resource,
            createKey,
            data
        )) as DareDropApiRequestBaseConfig

        const res = await daredropApiRequest({
            ...requestBody,
            schema: z.unknown(),
        })

        return {
            data: await getResourceData(resource, editListItemsIdKey, res),
        }
    },
    update: async (resource, params): Promise<Record<string, any>> => {
        const requestBody = (await getResourceData(
            resource,
            updateKey,
            params
        )) as DareDropApiRequestBaseConfig

        const res = await daredropApiRequest({
            ...requestBody,
            schema: z.unknown(),
        })

        await getResourceData(resource, afterUpdateActionKey, res)

        if (res) {
            return {
                data: await getResourceData(resource, editOneItemIdKey, res),
            }
        }

        return {
            data: params.data,
        }
    },
    updateMany: async (resource, params): Promise<Record<string, any>> => {
        const { ids } = params
        const requestBody = (await getResourceData(
            resource,
            updateManyKey,
            ids
        )) as DareDropApiRequestBaseConfig

        const res = daredropApiRequest({
            ...requestBody,
            schema: z.unknown(),
        })

        return { data: res }
    },
    delete: async (resource, params) => {
        const requestBody = (await getResourceData(
            resource,
            deleteKey,
            params
        )) as DareDropApiRequestBaseConfig

        await daredropApiRequest({
            ...requestBody,
            schema: z.unknown(),
        })

        return {
            data: { params },
        }
    },
    deleteMany: async (resource, params): Promise<Record<string, any>> => {
        const payload = await getResourceData(resource, deleteManyKey, params)
        if (Array.isArray(payload)) {
            await Promise.all(
                payload.map(async (requestBody: DareDropApiRequestBaseConfig) =>
                    daredropApiRequest({
                        ...requestBody,
                        schema: z.unknown(),
                    })
                )
            )
            return {
                data: [],
            }
        }

        await daredropApiRequest({
            ...(payload as DareDropApiRequestBaseConfig),
            schema: z.unknown(),
        })

        return { data: [] }
    },
} as DataProvider & {
    createMany: <T extends RaRecord<any>>(
        resource: string,
        params: { data: T }
    ) => Promise<{ data: T[] }>
}

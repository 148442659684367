import { z } from 'zod'

export const tiktokReportRequestBodySchema = z
    .object({
        topics: z.array(z.string()).optional(),
        excludedTopics: z.array(z.string()).optional(),
        regions: z.array(z.string()).optional(),
        residentCountries: z.array(z.string()).optional(),
        brandName: z.string().optional(),
        followersMin: z.number().optional(),
        followersMax: z.number().optional(),
        engagementRateMin: z.number().optional(),
        engagementRateMax: z.number().optional(),
        hasEmail: z.boolean().optional(),
        receiveInvitationsSettingOn: z.boolean().optional(),
        audienceAgeScope: z.string().optional(),
        audienceAgeMinPercentage: z.number().optional(),
        audienceGenderScope: z.string().optional(),
        audienceGenderMinDecimal: z.number().optional(),
    })
    .refine(
        (data) => {
            const audienceGenderPairValid =
                (!data.audienceGenderScope &&
                    typeof data.audienceGenderMinDecimal !== 'number') ||
                (data.audienceGenderScope &&
                    typeof data.audienceGenderMinDecimal !== 'number') ||
                (typeof data.audienceGenderMinDecimal === 'number' &&
                    !data.audienceGenderScope)

            return audienceGenderPairValid
        },
        {
            message:
                'audienceGenderScope and audienceGenderMinDecimal are required when one is provided',
            path: ['audienceGenderScope', 'audienceGenderMinDecimal'],
        }
    )
    .refine(
        (data) => {
            const followersPairValid =
                typeof data.followersMin === 'number' &&
                typeof data.followersMax === 'number'
                    ? data.followersMin < data.followersMax
                    : true
            return followersPairValid
        },
        {
            message:
                'followersMin must be less than followersMax when both are provided',
            path: ['followersMin', 'followersMax'],
        }
    )
    .refine(
        (data) => {
            const engagementRatePairValid =
                typeof data.engagementRateMin === 'number' &&
                typeof data.engagementRateMax === 'number'
                    ? data.engagementRateMin < data.engagementRateMax
                    : true
            return engagementRatePairValid
        },
        {
            message:
                'engagementRateMin must be less than engagementRateMax when both are provided',
            path: ['engagementRateMin', 'engagementRateMax'],
        }
    )

export type TiktokReportRequestBody = z.infer<
    typeof tiktokReportRequestBodySchema
>

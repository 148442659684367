import { daredropApiUrl, stackDomain } from 'constants/app'
import qs from 'qs'
import requestHandler from 'utils/requestHandler'
import { TokensService } from '../services/tokens.service'

export default async ({ userId }: { userId: string }) => {
    const res = await requestHandler<{
        body?: { accessToken: string; refreshToken: string }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'ADMIN_GET_TOKEN',
            payload: { userId },
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })

    if (!res) return

    const { statusCode, body } = res

    if (statusCode === 200 && body) {
        const hashParams = qs.stringify(body)
        const href = `https://${stackDomain}/drops#${hashParams}`
        window.open(href, '_blank')
    }
}

import { ReactElement, useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import useDebounce from 'hooks/useDebounce'
import { FormContext } from 'components/Form'
import { FilterOption } from 'types'

const AsyncFilterAutocomplete = ({
    source,
    label,
    asyncOptionsFn,
    multiple,
}: {
    label?: string
    source: string
    asyncOptionsFn: (input: string) => Promise<FilterOption[]>
    multiple?: boolean
}): ReactElement => {
    const [options, setOptions] = useState<FilterOption[]>([])
    const [inputValue, setInputValue] = useState('')
    const debouncedInputValue = useDebounce<string>(inputValue, 300)

    useEffect(() => {
        if (!debouncedInputValue.length) {
            return
        }
        const onChange = async (input: string) => {
            const res = await asyncOptionsFn(input)
            return setOptions(res)
        }
        onChange(debouncedInputValue)
    }, [debouncedInputValue, asyncOptionsFn])

    return (
        <FormContext.Consumer>
            {({ handleFormChange, form }) => (
                <Autocomplete
                    options={options}
                    value={form[source] || []}
                    onChange={(e, v) => handleFormChange(source, v)}
                    multiple={multiple}
                    getOptionLabel={(option) => option.label || option}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) =>
                        event && setInputValue(newInputValue)
                    }
                    filterOptions={(x) => x}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            variant="outlined"
                        />
                    )}
                />
            )}
        </FormContext.Consumer>
    )
}

export default AsyncFilterAutocomplete

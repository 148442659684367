import { ComponentType } from 'react'
import Chart from 'components/Chart'

const styles = {
    sectionWrapper: { paddingBottom: 10, borderBottom: '2px solid grey' },
    chartsWrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap' as const,
        gap: 20,
        justifyContent: 'center',
        paddingBottom: 50,
    },
    chart: { width: '30%', maxHeight: 300 },
}

type ChartData = {
    title: string
    formattedTimestamps: string[]
    metricValues: number[]
}

type Props = {
    sectionTitle: string
    chartsData: ChartData[]
}

const ChartsSection: ComponentType<Props> = ({ sectionTitle, chartsData }) => (
    <div style={styles.sectionWrapper}>
        <h2>{sectionTitle}</h2>
        <div style={styles.chartsWrapper}>
            {chartsData.map(({ title, formattedTimestamps, metricValues }) => (
                <div style={styles.chart} key={title}>
                    <Chart
                        title={title}
                        labels={formattedTimestamps}
                        values={metricValues}
                    />
                </div>
            ))}
        </div>
    </div>
)

export default ChartsSection

import jwtDecode from 'jwt-decode'
import { getCookie, setCookie } from '../cookies'
import { AuthName, authNames } from '../constants/cookies'
import { AuthApiService } from './auth-api/auth-api.service'
import { AuthApi_DecodedToken } from './auth-api/auth-api.types'
import { LockMethod } from '../decorators/lock.decorator'

export class TokensService {
    @LockMethod()
    static async refreshAppToken() {
        const appRefreshToken = this.getAppRefreshToken()

        if (!appRefreshToken) {
            throw new Error('Cannot find token to refresh')
        }

        const newTokens = await AuthApiService.refreshToken(appRefreshToken)

        this.setAppToken(newTokens.accessToken)
    }

    static decodeToken(token: string) {
        return jwtDecode<AuthApi_DecodedToken>(token)
    }

    static #setToken(token: string, tokenName: AuthName) {
        const decodedToken = this.decodeToken(token)
        return setCookie(tokenName, token, decodedToken.exp)
    }

    static #getToken(tokenName: AuthName) {
        return getCookie(tokenName)
    }

    static setAppToken(token: string) {
        return this.#setToken(token, authNames.ADMIN_APP_TOKEN)
    }

    static setAppRefreshToken(token: string) {
        return this.#setToken(token, authNames.ADMIN_APP_REFRESH_TOKEN)
    }

    static getAppToken() {
        return this.#getToken(authNames.ADMIN_APP_TOKEN)
    }

    static getAppRefreshToken() {
        return this.#getToken(authNames.ADMIN_APP_REFRESH_TOKEN)
    }

    static isTokenExpired(token: string) {
        const decodedToken = jwtDecode<AuthApi_DecodedToken>(token)
        return decodedToken.exp - Math.floor(Date.now() / 1000) <= 0
    }

    static clearTokens() {
        setCookie(authNames.ADMIN_APP_TOKEN, '', -1)
        setCookie(authNames.ADMIN_APP_REFRESH_TOKEN, '', -1)
    }
}

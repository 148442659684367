import { memo } from 'react'
import { RadioField } from './RadioField'

type YesNoFilter = {
    source: string
    label: string
}

// eslint-disable-next-line react/display-name,import/prefer-default-export
export const YesNoFilter = memo<YesNoFilter>(({ label, source }) => (
    <RadioField
        source={source}
        label={label}
        options={[
            {
                value: true,
                label: 'Yes',
            },
            {
                value: false,
                label: 'No',
            },
        ]}
    />
))

import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    EditButton,
    TopToolbar,
    ExportButton,
    Button,
} from 'react-admin'
import { Add } from '@material-ui/icons'
import PaidOffPlatformButton from 'components/PaidOffPlatformButton'
import { useQueryParams } from 'hooks/useQueryParams'
import browserHistory from 'browserHistory'
import { resourcesIds } from 'resources'

type Platform = 'twitch' | 'youtube' | 'tiktok'

const getProfileLinkByPlatformMap: Record<
    Platform,
    (mnemonicId: string) => string
> = {
    twitch: (mnemonicId) => `https://www.twitch.tv/${mnemonicId}`,
    tiktok: (mnemonicId) => `https://www.tiktok.com/@${mnemonicId}`,
    youtube: (mnemonicId) => `https://www.youtube.com/channel/${mnemonicId}`,
}

const DropEventListActions = () => {
    const dropId = useQueryParams('value')

    return (
        <TopToolbar>
            <Button
                onClick={() => {
                    browserHistory.push({
                        pathname: `${resourcesIds.CAMPAIGN_POINTS_DETAILS}/create`,
                        search: `?value=${dropId}`,
                    })
                }}
                startIcon={<Add />}
                label="Create"
            />
            <ExportButton />
        </TopToolbar>
    )
}

const CampaignsReportData = () => (
    <List
        disableSyncWithLocation
        empty={false}
        title="Campaign points details"
        sort={{ field: 'pointsAwarded', order: 'ASC' }}
        actions={<DropEventListActions />}
    >
        <Datagrid isRowSelectable={() => false}>
            <TextField label="Creator name" source="displayName" />
            <FunctionField
                label="Channel's link"
                render={(record: {
                    platform: Platform
                    mnemonicId: string
                }) => {
                    const { platform, mnemonicId } = record
                    const href =
                        getProfileLinkByPlatformMap[platform](mnemonicId)
                    return (
                        <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                            href={href}
                        >
                            {href}
                        </a>
                    )
                }}
            />
            <TextField source="pointsAwarded" />
            <FunctionField
                label="Value"
                render={(record: Record<string, any>) =>
                    `$${record.value.toFixed(2)}`
                }
            />
            <TextField source="viewerHoursTotal" label="Viewer Hours Total" />
            <TextField
                source="viewerHoursAwarded"
                label="Viewer Hours Awarded"
            />
            <TextField source="clicks" />
            <TextField source="paypalEmail" />
            <EditButton label="Edit awarded points" />
            <PaidOffPlatformButton
                render={(record) => {
                    if ('paidOffPlatform' in record) {
                        return !record.paidOffPlatform
                    }
                    return true
                }}
            />
        </Datagrid>
    </List>
)

export default CampaignsReportData

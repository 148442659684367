/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Lock decorator
 *
 * Awaits for the previous call to finish before starting a new one
 *
 * Example usage
 * ```typescript
 * class ExampleClass {
 *   @LockMethod()
 *   async getSomething() {
 *     return 'something'
 *   }
 * }
 * ```
 *
 */
export function LockMethod() {
    const tasksMap = new Map<string, Promise<any>>()
    return function (
        originalMethod: any,
        context: ClassMethodDecoratorContext
    ) {
        async function replacementMethod(this: any, ...args: any[]) {
            let argsStringified
            try {
                argsStringified = JSON.stringify(args)
            } catch (err) {
                argsStringified = args.join(',')
            }
            const key = `${context.name.toString()}(${argsStringified})`
            try {
                let task = tasksMap.get(key)
                if (!task) {
                    task = originalMethod.call(this, ...args)
                    tasksMap.set(key, task!)
                }
                return await task
            } finally {
                // free
                tasksMap.delete(key)
            }
        }
        return replacementMethod
    }
}

import { ComponentType, Dispatch, SetStateAction } from 'react'
import { Button } from '@material-ui/core'
import moment from 'moment-mini'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { GetMetricsFilters } from 'types'
import MuiTextField from '@mui/material/TextField'

const makeLocalAppearUTC = (value: string) => {
    const getTimezoneOffset = (date: Date) => date.getTimezoneOffset() * 60000

    const dateTime = new Date(value)
    const utcFromLocal = new Date(
        dateTime.getTime() + getTimezoneOffset(dateTime)
    )
    return utcFromLocal
}

const styles = {
    wrapper: {
        paddingBottom: 10,
        borderBottom: '2px solid grey',
    },
    filters: {
        display: 'flex',
        gap: 20,
    },
}

interface FiltersProps {
    filters: GetMetricsFilters
    setFilters: Dispatch<SetStateAction<GetMetricsFilters>>
}

const Filters: ComponentType<FiltersProps> = ({ filters, setFilters }) => {
    const timeButtonsDescription = [
        {
            label: 'Last day',
            onClick: () =>
                setFilters(() => ({
                    startAt: moment().utc().subtract(1, 'days').format(),
                    endAt: moment().utc().format(),
                })),
        },
        {
            label: 'Last 3 days',
            onClick: () =>
                setFilters(() => ({
                    startAt: moment().utc().subtract(3, 'days').format(),
                    endAt: moment().utc().format(),
                })),
        },
        {
            label: 'Last week',
            onClick: () =>
                setFilters(() => ({
                    startAt: moment().utc().subtract(7, 'days').format(),
                    endAt: moment().utc().format(),
                })),
        },
    ]

    return (
        <div style={styles.wrapper}>
            <h2>Filters:</h2>
            <div style={styles.filters}>
                <p>Time range:</p>
                {timeButtonsDescription.map(({ label, onClick }) => (
                    <Button key={label} onClick={onClick} variant="outlined">
                        {label}
                    </Button>
                ))}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        label="Start at"
                        value={makeLocalAppearUTC(filters.startAt)}
                        onChange={() => undefined}
                        onAccept={(value: moment.Moment | null) => {
                            if (!value) return
                            setFilters((prev) => ({
                                ...prev,
                                startAt: value.format(),
                            }))
                        }}
                        renderInput={(params) => <MuiTextField {...params} />}
                    />
                    <DateTimePicker
                        label="End at"
                        value={makeLocalAppearUTC(filters.endAt)}
                        onChange={() => undefined}
                        onAccept={(value: moment.Moment | null) => {
                            if (!value) return
                            setFilters((prev) => ({
                                ...prev,
                                endAt: value.format(),
                            }))
                        }}
                        renderInput={(params) => <MuiTextField {...params} />}
                    />
                </LocalizationProvider>
            </div>
        </div>
    )
}

export default Filters

import { ComponentType, useState } from 'react'
import Form from 'components/Form'
import FilterAutocomplete from 'components/FilterAutocomplete'
import languageOptions from 'utils/languageOptions'
import FilterTwitchGamesAutocomplete from 'components/FilterTwitchGamesAutocomplete'
import { RadioField } from 'components/RadioField'
import {
    requestCreatorsReportAlt,
    useReportsMaintenance,
} from 'utils/creatorApi'
import { Modal } from '@mui/material'
import { Button } from 'react-admin'
import { CloudUpload } from '@material-ui/icons'
import { YesNoFilter } from 'components/YesNoFilter'
import { UploadCreatorListModal } from 'components/UploadCreatorListModal'
import { InputOption } from 'types/forms'
import { CcvField } from 'components/CcvField'
import { PercentageField } from 'components/PercentageField'
import { downloadFile } from 'utils/downloadFile'

const DatePicker = () => (
    <RadioField
        source="timeRange"
        label="Date range"
        options={[
            {
                value: 7,
                label: '7 days',
            },
            {
                value: 14,
                label: '14 days',
            },
            {
                value: 30,
                label: '30 days',
            },
            {
                value: 60,
                label: '60 days',
            },
            {
                value: 90,
                label: '90 days',
            },
            {
                value: 180,
                label: '180 days',
            },
            {
                value: 365,
                label: '365 days',
            },
        ]}
    />
)

const style = {
    width: 280,
    margin: '0 auto',
    display: 'grid',
    gridGap: 20,
}

type RequestCreatorsReportAltFormData = {
    timeRange?: string
    languages: InputOption<string>[]
    gameIds: InputOption<string>[]
    excludedGameIds: InputOption<string>[]
    minCcv?: number
    maxCcv?: number
    hasEmail?: boolean
    contacted?: boolean
    minStreamTimePercentage?: number
    maxStreamTimePercentage?: number
}

export const onCreatorsReportSubmit = async (
    formData: RequestCreatorsReportAltFormData
) => {
    const res = await requestCreatorsReportAlt({
        ...formData,
        excludedGameIds: formData.excludedGameIds?.map((game) => game.value),
        gameIds: formData.gameIds?.map((game) => game.value),
        languages: formData.languages?.map((language) => language.value),
    })
    const { url } = res.body
    await downloadFile(url)
}

const RequestCreatorsReportFormAlt: ComponentType = () => {
    const [modalOpened, setModalOpened] = useState(false)
    const { isReportsMaintenance, maintenanceTimeLeft } =
        useReportsMaintenance()

    return (
        <div>
            <Button
                label="Upload contacted creators list"
                onClick={() => setModalOpened(true)}
                startIcon={<CloudUpload />}
            />
            <Modal open={modalOpened} onClose={() => setModalOpened(false)}>
                <UploadCreatorListModal setModalOpened={setModalOpened} />
            </Modal>
            <Form
                style={style}
                submits={[
                    {
                        onSubmit: onCreatorsReportSubmit,
                        disabled: isReportsMaintenance,
                    },
                ]}
                defaults={{ gameIds: [], languages: [], excludedGameIds: [] }}
                clear={false}
            >
                <FilterTwitchGamesAutocomplete
                    label="Games"
                    source="gameIds"
                    multiple
                />
                <FilterTwitchGamesAutocomplete
                    label="Excluded games"
                    source="excludedGameIds"
                    multiple
                />
                <FilterAutocomplete
                    label="Languages"
                    source="languages"
                    options={languageOptions}
                />
                <CcvField />
                <PercentageField
                    source="minStreamTimePercentage"
                    label=">= % of Stream Time"
                />
                <PercentageField
                    source="maxStreamTimePercentage"
                    label="<= % of Stream Time"
                />
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <DatePicker />
                    <div>
                        <YesNoFilter label="Contacted" source="contacted" />
                        <YesNoFilter label="Has Email" source="hasEmail" />
                        <YesNoFilter label="Has TikTok" source="hasTiktok" />
                    </div>
                </div>
            </Form>
            {isReportsMaintenance && (
                <span style={{ margin: '5px auto', display: 'table' }}>
                    Maintenance. Estimated minutes until we&apos;ll be back:{' '}
                    {maintenanceTimeLeft}
                </span>
            )}
        </div>
    )
}

export default RequestCreatorsReportFormAlt

import { stringify } from 'qs'

export type AjaxPayload = {
    url: string
    method?: string
    body?: Record<string, unknown>
    queryParams?: Record<string, string | number>
    headers?: Record<string, string>
    file?: File
}

export const wait = (ms: number) =>
    new Promise((resolve) => {
        setTimeout(async () => {
            resolve(ms)
        }, ms)
    })

export const HTTP_STATUS_CODES = {
    OK: 200,
    UNAUTHORIZED: 401,
} as const

const ajax = async <T>(config: AjaxPayload, retries = 1): Promise<T> =>
    new Promise((resolve, reject) => {
        const { url, method, body, queryParams, headers = {}, file } = config
        const queryString = queryParams
            ? `?${stringify(queryParams, { indices: false })}`
            : ''
        const xhr = new XMLHttpRequest()
        const jsonBody = body ? JSON.stringify(body) : undefined
        const defaultMethod = method || 'GET'
        const defaultHeaders = {
            ...headers,
            'Content-Type': headers['Content-Type'] || 'application/json',
        }

        xhr.open(defaultMethod, `${url}${queryString}`)

        Object.entries(defaultHeaders).forEach(([key, value]) => {
            xhr.setRequestHeader(key, value)
        })

        xhr.onload = () => {
            let parsed = xhr.response
            try {
                parsed = JSON.parse(parsed)
            } catch (e) {
                // response not json, leave parsed as text and continue
            }
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(parsed)
            } else {
                if (retries > 0) {
                    wait(1000).then(() => {
                        resolve(ajax(config, retries - 1))
                    })
                }
                reject(parsed)
            }
        }
        xhr.onerror = () => {
            reject(new Error('Network Error'))
        }

        if (file) {
            xhr.send(file)
        } else {
            xhr.send(jsonBody)
        }
    })

export default ajax

import { resourcesIds } from 'resources'
import { editOneItemIdKey, getOneKey } from 'keys'

export type GetPointsStatisticResponseBody = {
    points: number
    pointsQueuedToPayout: number
}

export default {
    [resourcesIds.POINTS_STATISTICS]: {
        [getOneKey]: () => ({
            endpointId: 'GET_POINTS_STATISTICS',
            payload: {},
        }),
        [editOneItemIdKey]: ({
            points,
            pointsQueuedToPayout,
        }: GetPointsStatisticResponseBody) => ({
            points,
            pointsQueuedToPayout,
            id: 0,
        }),
    },
}

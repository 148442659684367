import {
    Create,
    minValue,
    regex,
    required,
    SimpleForm,
    TextInput,
    useInput,
    useRefresh,
} from 'react-admin'
import moment from 'moment'
import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { FormControl, TextField, TextFieldProps } from '@material-ui/core'
import { urlRegex } from '../../constants/regex'
import { PessimisticEdit } from '../../components/PessimisticEdit'

export const CreateCampaignSurveyForm = () => {
    const refresh = useRefresh()
    const onSuccess = () => {
        refresh()
    }

    return (
        <Create mutationOptions={{ onSuccess }} redirect={false}>
            <CampaignSurveyForm title="Schedule Automate Survey" />
        </Create>
    )
}

export const EditCampaignSurveyForm = () => {
    return (
        <PessimisticEdit>
            <CampaignSurveyForm title="Edit Automate Survey" />
        </PessimisticEdit>
    )
}

type CampaignSurveyFormProps = {
    title: string
}

export const CampaignSurveyForm = ({ title }: CampaignSurveyFormProps) => {
    return (
        <SimpleForm>
            <div style={{ width: '100%' }}>
                <p>{title}</p>
                <hr />
                <div
                    style={{
                        width: '40%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <CampaignSurveyUrlInput />
                    <CampaignSurveyDateInput />
                </div>
            </div>
        </SimpleForm>
    )
}

const CampaignSurveyUrlInput = () => {
    return (
        <TextInput
            source="url"
            label="Url for survey"
            validate={[
                required('Please provide url for survey'),
                regex(urlRegex, 'Please provide correct url'),
            ]}
        />
    )
}

const CampaignSurveyDateInput = () => {
    const { field, fieldState } = useInput({
        defaultValue: null,
        source: 'surveyDate',
        validate: [
            required('Please provide send date'),
            minValue(moment().add(1, 'day').format('YYYY-MM-DD')),
        ],
    })

    return (
        <div>
            <FormControl error={Boolean(fieldState.error)}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        views={['day', 'hours']}
                        label="Date to send email"
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) => {
                            field.onChange(
                                moment(date).startOf('hour').utc().format()
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...(params as TextFieldProps)}
                                size="small"
                                variant="filled"
                                defaultValue={null}
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </LocalizationProvider>
            </FormControl>
        </div>
    )
}

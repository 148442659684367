import { ComponentType } from 'react'

type Props = {
    queues: {
        queueUrl: string
        messageCount: number
    }[]
}

const styles = {
    container: { borderBottom: '2px solid grey' },
    table: { width: '40%' },
    tdCenter: { textAlign: 'center' as const },
}

const QueueMessagesSection: ComponentType<Props> = ({ queues }) => (
    <div style={styles.container}>
        <h2>DLQ metrics:</h2>
        <table style={styles.table}>
            <thead>
                <tr>
                    <th>Queue</th>
                    <th>Message count</th>
                </tr>
            </thead>
            <tbody>
                {queues.map(({ queueUrl, messageCount }) => (
                    <tr key={queueUrl}>
                        <td>
                            <span>{queueUrl.split('/').slice(-1)[0]}</span>
                        </td>
                        <td style={styles.tdCenter}>
                            <span>{messageCount}</span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
)

export default QueueMessagesSection

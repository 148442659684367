import { saveAs } from 'file-saver'
import filter from 'ramda/src/filter'
import includes from 'ramda/src/includes'
import isEmpty from 'ramda/src/isEmpty'
import reduce from 'ramda/src/reduce'
import toPairs from 'ramda/src/toPairs'
import { FilterProps, FilteredData, ResourceActions } from 'types/resources'
import { DareDropApiRequestBaseConfig } from 'utils/daredrop-api/apiRequest'

export type Brand = {
    name: string
    website: string
    email: string
    id: string
}

export type CodeReward = {
    id: string
    name: string
    type: 'dropCode' | 'dropKey'
    status: 'available' | 'used'
    available: number
    used: number
    brand: Brand
}

export type KeyReward = {
    region: string
    platform: string
    typeReadable: string
} & CodeReward

export type SingleReward = {
    id: string
    brandId: string
    available: number
    type: 'dropCode' | 'dropKey'
}

export type Reward = KeyReward | CodeReward

const RewardEndpoint = {
    GET_REWARDS: 'GET_REWARDS',
    GET_REWARD: 'GET_REWARD',
    USE_REWARDS: 'USE_REWARDS',
}

export const idSerializer = {
    serialize: (rewardType: string, rewardId: string, brandId: string) =>
        `${rewardType}---${rewardId}---${brandId}`,
    deserialize: (id: string) => {
        const [rewardType, rewardId, brandId] = id.split('---')
        return { rewardType, rewardId, brandId }
    },
}

type RewardUpdateOptions = {
    data: {
        decryptAmount: number
        id: string
    }
}

export const rewardActions: ResourceActions<
    Reward,
    RewardUpdateOptions,
    SingleReward
> = {
    getList: (): DareDropApiRequestBaseConfig => {
        return {
            endpointId: RewardEndpoint.GET_REWARDS,
            payload: {},
        }
    },
    editListItemsId: (records: Reward[]): Reward[] =>
        records.map((record: Reward) => ({
            ...record,
            id: idSerializer.serialize(record.type, record.id, record.brand.id),
        })),
    getOne: ({ id }: { id: string }): DareDropApiRequestBaseConfig => ({
        endpointId: RewardEndpoint.GET_REWARD,
        payload: idSerializer.deserialize(id),
    }),
    editOneItemId: (record: SingleReward): SingleReward => ({
        ...record,
        id: idSerializer.serialize(record.type, record.id, record.brandId),
    }),
    filterList: (filterData: FilterProps<Reward>): FilteredData<Reward> => {
        const { filterParams, data: rewardsData } = filterData
        const filters = {
            name: (value: string, data: Reward[]) =>
                filter(({ name }) => includes(value, name), data),
            brandName: (value: string, data: Reward[]) =>
                filter(({ brand }) => includes(value, brand.name || ''), data),
        }
        type Filters = keyof typeof filters

        const dataFiltered = reduce(
            (acc: Reward[], filterProp: string[]) => {
                const [prop, value] = filterProp

                return filters[prop as Filters](
                    value,
                    isEmpty(acc) ? rewardsData : acc
                )
            },
            [],
            toPairs(filterParams)
        )
        return { dataFiltered }
    },
    update: (params: RewardUpdateOptions): DareDropApiRequestBaseConfig => {
        const { data } = params
        const { decryptAmount, id } = data
        return {
            endpointId: RewardEndpoint.USE_REWARDS,
            payload: {
                amount: decryptAmount,
                ...idSerializer.deserialize(id),
            },
        }
    },
    afterUpdateAction: (res: string[]) => {
        const filaData = res.join('\n')
        const blob = new Blob([filaData], {
            type: 'text/plain;charset=utf-8',
        })
        saveAs(blob, `decrypted_rewards_(${res.length}).txt`)
        return {}
    },
}

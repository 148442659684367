import { ComponentType, useState } from 'react'
import {
    List,
    Datagrid,
    NumberField,
    DateField,
    UrlField,
    EmailField,
    TextInput,
    SelectInput,
    TextField,
    Filter,
    ExportButton,
    useListContext,
    FilterProps,
} from 'react-admin'
import { DaredropApi } from 'utils/daredrop-api'
import { Box, Button } from '@mui/material'
import {
    readableStates,
    verificationStates,
} from 'resourceDescriptions/verifications'

const PostFilters = (props: Omit<FilterProps, 'children'>) => {
    const { filterValues, setFilters } = useListContext()
    const [exportLoading, setExportLoading] = useState(false)
    return (
        <div>
            <p>Use only one filter at the same time</p>
            <Filter {...props}>
                <TextInput
                    label="User Id filter"
                    key="id"
                    source="id"
                    alwaysOn
                />
                <TextInput
                    label="Email filter"
                    key="email"
                    source="email"
                    alwaysOn
                />
                <TextInput
                    label="Username filter"
                    key="displayName"
                    source="displayName"
                    alwaysOn
                />
                <SelectInput
                    choices={[
                        { id: 'twitch', name: 'Twitch' },
                        { id: 'youtube', name: 'YouTube' },
                        { id: 'tiktok', name: 'TikTok' },
                    ]}
                    label="Platform filter"
                    key="platform"
                    source="platform"
                    alwaysOn
                />
                <SelectInput
                    choices={verificationStates.map((state) => ({
                        id: state,
                        name: readableStates[state],
                    }))}
                    label="Verification state"
                    key="verificationState"
                    source="verificationState"
                    alwaysOn
                />
            </Filter>
            <Box component="span" mr={2} mb={1.5}>
                <ExportButton
                    disabled={exportLoading}
                    exporter={async () => {
                        setExportLoading(true)
                        const filterEntries = Object.entries(filterValues)
                        const [filterName, filterValue] = filterEntries.length
                            ? (filterEntries.at(0) as [string, string])
                            : []
                        try {
                            await DaredropApi.sendSignedUpCreatorsReport({
                                filterValue,
                                filterName,
                            })
                            // eslint-disable-next-line no-alert
                            window.alert(
                                'Generating report might take a while. You will receive an email when it is ready.'
                            )
                        } finally {
                            setExportLoading(false)
                        }
                    }}
                />
            </Box>
            <Box component="span" mb={1.5}>
                <Button variant="outlined" onClick={() => setFilters({}, [])}>
                    Clear
                </Button>
            </Box>
        </div>
    )
}

const SignedUpCreators: ComponentType = () => (
    <List
        empty={false}
        title="Signed Up Creators"
        sort={{ field: 'created', order: 'ASC' }}
        pagination={false}
        filters={<PostFilters />}
        exporter={false}
    >
        <Datagrid isRowSelectable={() => false}>
            <TextField label="User id" source="id" />
            <UrlField label="Channel URL" source="channelUrl" />
            <UrlField label="Profile URL" source="daredropProfileUrl" />
            <EmailField source="email" />
            <NumberField label="Drops started" source="dropsStarted" />
            <NumberField label="Drops completed" source="dropsCompleted" />
            <TextField label="Delivery rate" source="deliveryRate" />
            <NumberField label="7 days CCV" source="ccv7" />
            <NumberField label="14 days CCV" source="ccv14" />
            <NumberField label="30 days CCV" source="ccv30" />
            <TextField label="Languages" source="languages" />
            <NumberField label="Points (Actual)" source="pointsSum" />
            <NumberField label="Points (Total)" source="pointsTotal" />
            <UrlField label="Last drop" source="lastDropUrl" />
            <TextField label="Last action type" source="lastActionType" />
            <UrlField
                label="Last action project"
                source="lastActionProjectUrl"
            />
            <DateField
                label="Last joined to Drop"
                source="lastJoinedDropDate"
            />
            <DateField label="Latest activity date" source="lastActionDate" />
            <DateField label="Created" source="created" />
        </Datagrid>
    </List>
)

export default SignedUpCreators

import { resourcesIds } from 'resources'
import { editListItemsIdKey, getListKey } from '../keys'

export default {
    [resourcesIds.PREVIOUS_DARES_RESOURCE_ID]: {
        [getListKey]: (data: {
            pagination: { perPage: number; page: number }
            filter: { value: string; filter: string }
        }) => {
            return {
                endpointId: 'GET_PREVIOUS_DARES',
                payload: {
                    limit: data.pagination.perPage,
                    page: data.pagination.page,
                    filterValue: data.filter.value,
                    filterName: data.filter.filter,
                },
                pagination: true,
            }
        },
        [editListItemsIdKey]: (
            rawData: Record<string, any>[]
        ): Record<string, any> => rawData,
    },
}

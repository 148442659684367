import { daredropApiUrl } from 'constants/app'
import requestHandler from 'utils/requestHandler'
import { TwitchGame, StreamsStatsReportPayloadItem } from 'types'
import moment from 'moment/moment'
import { TokensService } from '../services/tokens.service'

export type RequestCreatorsPayload = {
    dateTo?: string
    dateFrom?: string
    timeRange?: string
    languages: string[]
    gameIds: string[]
    excludedGameIds: string[]
    minCcv?: number
    maxCcv?: number
    hasEmail?: boolean
    contacted?: boolean
    creatorIds?: string[]
    minStreamTimePercentage?: number
    maxStreamTimePercentage?: number
}

export const requestCreatorsReport = async (
    body: RequestCreatorsPayload
): Promise<{ body: Record<string, unknown> }> => {
    const { dateFrom, creatorIds, dateTo, languages, gameIds } = body

    if (!gameIds.length) {
        throw new Error('Games are missing')
    }
    if (!creatorIds?.length && !languages.length) {
        throw new Error('Creator ids or languages are missing')
    }
    if (!dateFrom || !dateTo) {
        throw new Error('Date is missing')
    }

    return requestHandler<{
        body: { data: TwitchGame[]; pagination: { cursor: string } }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body,
                url: `creators-report`,
                method: 'POST',
            },
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })
}

type RequestCreatorsReportAltPayload = Omit<
    RequestCreatorsPayload,
    'creatorIds'
>

export const requestCreatorsReportAlt = async (
    body: RequestCreatorsReportAltPayload
) => {
    const {
        timeRange,
        gameIds,
        minStreamTimePercentage,
        maxStreamTimePercentage,
    } = body
    if (!gameIds.length) {
        throw new Error('Games are missing')
    }
    if (!timeRange) {
        throw new Error('Date is missing')
    }

    return requestHandler<{
        body: { url: string }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body: {
                    ...body,
                    // parse percentage to decimal
                    ...(minStreamTimePercentage
                        ? {
                              minStreamTimePercentage:
                                  minStreamTimePercentage / 100,
                          }
                        : {}),
                    // parse percentage to decimal
                    ...(maxStreamTimePercentage
                        ? {
                              maxStreamTimePercentage:
                                  maxStreamTimePercentage / 100,
                          }
                        : {}),
                },
                url: `creators-report-alt`,
                method: 'POST',
            },
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })
}

export const getGameStats = async (body: RequestCreatorsReportAltPayload) => {
    const {
        timeRange,
        gameIds,
        minStreamTimePercentage,
        maxStreamTimePercentage,
    } = body
    if (!gameIds.length) {
        throw new Error('Games are missing')
    }
    if (!timeRange) {
        throw new Error('Date is missing')
    }

    return requestHandler<{
        body: {
            count: number
            averageViewers: number
            averageViewersSelected: number
            durationMinutes: number
            durationMinutesSelected: number
            followers: number
        }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body: {
                    ...body,
                    // parse percentage to decimal
                    ...(minStreamTimePercentage
                        ? {
                              minStreamTimePercentage:
                                  minStreamTimePercentage / 100,
                          }
                        : {}),
                    // parse percentage to decimal
                    ...(maxStreamTimePercentage
                        ? {
                              maxStreamTimePercentage:
                                  maxStreamTimePercentage / 100,
                          }
                        : {}),
                },
                url: `games/stats`,
                method: 'POST',
            },
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })
}

export type CreateStreamPayload = {
    averageViewers: number
    durationMinutes: number
    gameId: string
    gameName: string
    language: string
    startedAt: string
    endedAt: string
    title: string
    userId: string // platform ID
}

export const createStream = async (
    body: CreateStreamPayload
): Promise<{ body: Record<string, any> }> =>
    requestHandler<{
        body: { status: number }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body,
                url: `test-streams`,
                method: 'POST',
            },
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })

export const generateStreamsStatsReport = async (
    filters: StreamsStatsReportPayloadItem[]
): Promise<{ body: Record<string, any> }> =>
    requestHandler<{
        body: { status: number }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body: { filters },
                url: 'streams-stats-report',
                method: 'POST',
            },
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })

export const uploadCreators = async (usernames: string[]) =>
    requestHandler({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body: usernames.map((username) => ({
                    username,
                    contacted: true,
                })),

                url: `creators`,
                method: 'PATCH',
            },
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })

// estimated creators report maintenance time. i starts at 5:50 utc and takes ~ 2 hours
export const useReportsMaintenance = () => {
    const startDate = moment.utc().set('hour', 5).set('minute', 50)
    const endDate = moment.utc().set('hour', 8).set('minute', 0)

    const isReportsMaintenance = moment.utc().isBetween(startDate, endDate)

    const maintenanceTimeLeft = endDate.diff(moment.utc().format(), 'minutes')

    return {
        isReportsMaintenance,
        maintenanceTimeLeft: isReportsMaintenance ? maintenanceTimeLeft : 0,
    }
}

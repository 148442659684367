import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

/**
 * Used for fetching a single query string parameter.
 * @param {string} param
 */
export const useQueryParams = (param: string): string | null => {
    const [params] = useSearchParams()
    return params.get(param)
}

/**
 * Used for forms that are pre-filled with data from the URL query string.
 * The query string is parsed as JSON and returned.
 */
export const useQueryParamsSource = <T,>(): T | null => {
    const source = useQueryParams('source')

    if (!source) {
        return null
    }

    try {
        return JSON.parse(source)
    } catch (e) {
        return null
    }
}

/**
 * Used for forms that are pre-filled with data from the URL query string.
 * The query string is parsed and validated.
 * @param {z.ZodType} schema
 */
export const useSafeQueryParamsSource = <TSchema extends z.ZodTypeAny>(
    schema: TSchema
): z.infer<TSchema> => {
    const source = useQueryParamsSource()

    const parsed = schema.safeParse(source)

    if (!parsed.success) {
        return null
    }

    return parsed.data
}

import { z } from 'zod'

const cloudwatchMetricSchema = z.object({
    metricName: z.string(),
    formattedTimestamps: z.array(z.string()),
    metricValues: z.array(z.number()),
})

const cronSchema = z.object({
    name: z.string(),
    description: z.string().optional(),
    scheduleExpression: z.string(),
    targetFunctionName: z.string(),
    targetErrorsMetric: cloudwatchMetricSchema,
    targetDurationMetric: cloudwatchMetricSchema,
})

const logStreamSchema = z.object({
    logStreamName: z.string(),
    timestamp: z.number(),
    message: z.string(),
})

const dlqSchema = z.object({
    queueUrl: z.string(),
    messageCount: z.number(),
})

const logGroupSchema = z.object({
    logGroupName: z.string(),
    logStreams: z.array(logStreamSchema),
})

export const getStacksMetricsResponseSchema = z.object({
    rdsMetrics: z.record(z.array(cloudwatchMetricSchema)),
    crons: z.array(cronSchema),
    monitoringLogStreams: logGroupSchema,
    emailCountData: cloudwatchMetricSchema,
    dlqData: z.array(dlqSchema),
})

export type CloudwatchMetric = z.infer<typeof cloudwatchMetricSchema>
export type LogGroup = z.infer<typeof logGroupSchema>
export type CronData = z.infer<typeof cronSchema>

export type GetStacksMetricsResponseBody = z.infer<
    typeof getStacksMetricsResponseSchema
>

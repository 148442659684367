type SimpleObj = Record<string, string | number | undefined | null>
export const objectDiff = <T extends SimpleObj>(objA: T, objB: T) => {
    return Object.keys(objA).reduce<Partial<T>>((acc, item) => {
        const isDiff = objA[item] !== objB[item]
        if (isDiff) {
            return { ...acc, [item]: objB[item] }
        }
        return acc
    }, {})
}

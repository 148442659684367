import { AuthProvider } from 'react-admin'
import { HTTP_STATUS_CODES } from 'utils/ajax'
import { AuthService } from './services/auth.service'

import { AuthApi_Group } from './services/auth-api/auth-api.types'

export const isStatusIncluded = (err: unknown): err is { statusCode: number } =>
    typeof err === 'object' && err !== null && 'statusCode' in err

export const isUnauthorizedError = (err: unknown) => {
    if (isStatusIncluded(err)) {
        return err.statusCode === HTTP_STATUS_CODES.UNAUTHORIZED
    }
    return false
}

type DataProviderLoginParams = {
    username: string
    password: string
}

export default {
    login: async ({ username, password }: DataProviderLoginParams) => {
        AuthService.clearAuth()

        const credentials = {
            email: username,
            password,
        }

        await AuthService.signIn(credentials)
    },
    // when the dataProvider returns an error, check if this is an authentication error
    checkError: async (err: unknown) => {
        if (isUnauthorizedError(err)) {
            await AuthService.logOut()
            // eslint-disable-next-line no-console
            console.error(err)
            throw new Error('Unauthorized. Please login again')
        }
    },
    // chen the user navigates, make sure that their credentials are still valid
    checkAuth: async () => {
        const isSignedIn = AuthService.isSignedIn()

        if (!isSignedIn) {
            await AuthService.revalidateAuth()
        }

        if (!AuthService.hasAdminGroups()) {
            throw new Error(
                'Wrong permissions. Make sure you are signing in as admin'
            )
        }
    },
    logout: async () => {
        AuthService.logOut()
    },
    getPermissions: async (): Promise<AuthApi_Group[]> => {
        const isSignedIn = AuthService.isSignedIn()

        if (!isSignedIn) {
            if (!AuthService.isAbleToRefreshToken()) {
                return []
            }
            await AuthService.revalidateAuth()
        }

        return AuthService.getGroups() || []
    },
} satisfies AuthProvider

import { RadioOption } from 'components/RadioField'
import moment from 'moment-mini'

type Duration = 'days' | 'year'

const getDateForValue = (valueToSubtract: number, duration: Duration) =>
    moment().utc().subtract(valueToSubtract, duration).format()

export const dateRanges: RadioOption[] = [
    { value: getDateForValue(7, 'days'), label: '7 days' },
    { value: getDateForValue(14, 'days'), label: '14 days' },
    { value: getDateForValue(30, 'days'), label: '30 days' },
    { value: getDateForValue(60, 'days'), label: '60 days' },
    { value: getDateForValue(90, 'days'), label: '90 days' },
    { value: getDateForValue(180, 'days'), label: '180 days' },
    { value: getDateForValue(1, 'year'), label: 'Year' },
]

import { ComponentType } from 'react'
import { GetStacksMetricsResponseBody } from 'utils/creator-api'
import MonitoringLogs from './MonitoringLogs'
import CloudwatchEvents from './CloudwatchEvents'
import ChartsSection from './ChartsSection'
import RdsMetrics from './RdsMetrics'
import QueueMessagesSection from './QueueMessagesSection'

const styles = {
    container: {
        marginLeft: 20,
        display: 'grid',
        gap: 30,
    },
}

const Metrics: ComponentType<{ metrics: GetStacksMetricsResponseBody }> = ({
    metrics,
}) => (
    <div style={styles.container}>
        <RdsMetrics rdsMetrics={metrics.rdsMetrics} />
        <CloudwatchEvents cronsData={metrics.crons} />
        <ChartsSection
            sectionTitle="Creator stats:"
            chartsData={[
                {
                    title: 'Email Count',
                    ...metrics.emailCountData,
                },
            ]}
        />
        <QueueMessagesSection queues={metrics.dlqData} />
        <MonitoringLogs logGroup={metrics.monitoringLogStreams} />
    </div>
)
export default Metrics

import { ComponentType } from 'react'
import Form from 'components/Form'
import DateRangePicker from 'components/DateRangePicker'
import { generateStreamsStatsReport } from 'utils/creatorApi'
import { DaredropApi } from 'utils/daredrop-api'

const style = {
    width: 280,
    margin: '0 auto',
    display: 'grid',
    gridGap: 20,
}

const onSubmit = async (formData: { dateFrom: string; dateTo: string }) => {
    const filters = await DaredropApi.getStreamsStatsReportPayload(formData)
    return generateStreamsStatsReport(filters)
}

const StreamsStatsReportForm: ComponentType = () => (
    <Form style={style} submits={[{ onSubmit }]} defaults={{}}>
        <DateRangePicker
            sourceFrom="dateFrom"
            sourceTo="dateTo"
            label="Date range (manual)"
        />
    </Form>
)

export default StreamsStatsReportForm

import { useRequestCreatorsReportFormSource } from 'views/creators-report/hooks/useRequestCreatorsReportFormSource'
import { requestCreatorsReport } from 'utils/creatorApi'
import { InputOption } from 'types/forms'

type RequestCreatorsReportFormData = {
    dateTo?: string
    dateFrom?: string
    timeRange?: string
    languages: InputOption<string>[]
    gameIds: InputOption<string>[]
    excludedGameIds: InputOption<string>[]
    minCcv?: number
    maxCcv?: number
    hasEmail?: boolean
    contacted?: boolean
    creatorIds?: InputOption<string>[]
}

export const useRequestCreatorsForm = () => {
    const source = useRequestCreatorsReportFormSource()

    const onSubmit = (formData: RequestCreatorsReportFormData) => {
        return requestCreatorsReport({
            ...formData,
            excludedGameIds: formData.excludedGameIds.map((game) => game.value),
            gameIds: formData.gameIds.map((game) => game.value),
            languages: formData.languages.map((language) => language.value),
            creatorIds: formData.creatorIds?.map((creator) => creator.value),
        })
    }

    return {
        source,
        onSubmit,
    }
}

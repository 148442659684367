import { resourcesIds } from 'resources'
import { getListKey, editListItemsIdKey } from 'keys'
import { getCurrentQueryValue } from '../utils/common'

type Transaction = {
    index: number
    value: number
    platformId: string
}

export default {
    [resourcesIds.CAMPAIGN_TRANSACTIONS_RESOURCE_ID]: {
        [getListKey]: () => {
            const queryValue = getCurrentQueryValue()

            if (!queryValue) {
                return undefined
            }

            return {
                endpointId: 'GET_CAMPAIGN_TRANSACTIONS',
                payload: { campaignId: queryValue },
            }
        },
        [editListItemsIdKey]: (transactions: Transaction[]) => transactions,
    },
}

import { z } from 'zod'

const filterOptions = z.array(
    z.object({ label: z.string(), value: z.string() })
)

export const tiktokFiltersResponseBodySchema = z.object({
    topics: filterOptions,
    countries: filterOptions,
    ageGroups: filterOptions,
    genders: filterOptions,
})

export type TiktokFiltersResponseBody = z.infer<
    typeof tiktokFiltersResponseBodySchema
>

import { Datagrid, NumberField, TextField, List } from 'react-admin'

const CampaignTransactionsList = () => (
    <List disableSyncWithLocation empty={false} title="Campaign transactions">
        <Datagrid sort={{ field: 'platformId', order: 'ASC' }}>
            <TextField label="Platform ID" source="platformId" />
            <TextField label="Index" source="index" />
            <NumberField label="Value" source="value" />
        </Datagrid>
    </List>
)

export default CampaignTransactionsList

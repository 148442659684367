import { ReactElement } from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
import { FormContext } from './Form'

const FieldText = ({
    source,
    label,
}: {
    label?: string
    source: string
}): ReactElement => (
    <FormContext.Consumer>
        {({ form, handleFormChange }) => (
            <MuiTextField
                style={{ width: 280 }}
                label={label}
                type="text"
                variant="outlined"
                value={form[source] || ''}
                onChange={(e) => {
                    handleFormChange(source, e.target.value)
                }}
            />
        )}
    </FormContext.Consumer>
)

export default FieldText

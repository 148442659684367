import browserHistory from '../browserHistory'
import { AuthApiService } from './auth-api/auth-api.service'
import { TokensService } from './tokens.service'
import { AuthApi_Credentials, AuthApi_Group } from './auth-api/auth-api.types'

const adminGroups = ['admin', 'superAdmin'] satisfies AuthApi_Group[]

export class AuthService {
    static async signIn(credentials: AuthApi_Credentials) {
        const tokens = await AuthApiService.signIn(credentials)
        TokensService.setAppToken(tokens.accessToken)
        TokensService.setAppRefreshToken(tokens.refreshToken)
    }

    static isSignedIn() {
        const token = TokensService.getAppToken()

        if (!token) {
            return false
        }

        return !TokensService.isTokenExpired(token)
    }

    static logOut() {
        TokensService.clearTokens()
        browserHistory.replace('/login')
    }

    static getGroups() {
        try {
            const token = TokensService.getAppToken()
            if (!token) {
                return undefined
            }
            return TokensService.decodeToken(token).groups
        } catch (err) {
            console.error(err)
            return undefined
        }
    }

    static async revalidateAuth() {
        const appRefreshToken = TokensService.getAppRefreshToken()

        if (!appRefreshToken) {
            this.logOut()
            throw new Error(
                'There is no authentication token, please sign in again'
            )
        }

        try {
            await TokensService.refreshAppToken()
        } catch (err) {
            this.logOut()
            throw err
        }
    }

    static hasAdminGroups() {
        const groups = this.getGroups()

        if (!groups) {
            return false
        }

        return groups.some((group) =>
            adminGroups.includes(group as (typeof adminGroups)[number])
        )
    }

    static clearAuth() {
        return TokensService.clearTokens()
    }

    static isAbleToRefreshToken() {
        return Boolean(TokensService.getAppRefreshToken())
    }
}

import { ReactElement } from 'react'
import AsyncFilterAutocomplete from 'components/AsyncFilterAutocomplete'
import { FilterOption, TwitchUser } from 'types'
import { DaredropApi } from 'utils/daredrop-api'

const FilterTwitchGamesAutocomplete = ({
    source,
    label,
    multiple,
}: {
    source: string
    label?: string
    multiple?: boolean
}): ReactElement => (
    <AsyncFilterAutocomplete
        source={source}
        asyncOptionsFn={async (searchPhrase) => {
            const x = await DaredropApi.searchTwitchUsers(searchPhrase)
            return x.map(
                ({ id, display_name }: TwitchUser): FilterOption => ({
                    label: display_name,
                    value: id,
                })
            )
        }}
        label={label}
        multiple={multiple}
    />
)
export default FilterTwitchGamesAutocomplete

export const afterUpdateActionKey = 'afterUpdateAction'
export const editListItemsIdKey = 'editListItemsId'
export const editOneItemIdKey = 'editOneItemId'
export const filterListKey = 'filterList'
export const getListKey = 'getList'
export const getOneKey = 'getOne'
export const updateKey = 'update'
export const createKey = 'create'
export const updateManyKey = 'updateMany'
export const deleteKey = 'delete'
export const deleteManyKey = 'deleteMany'

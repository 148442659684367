import { ReactElement, useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import useDebounce from 'hooks/useDebounce'
import { Controller, useController } from 'react-hook-form'

export const AdminAsyncFilterAutocomplete = <T,>({
    source,
    label,
    asyncOptionsFn,
    multiple,
    optionLabel,
}: {
    label?: string
    source: string
    asyncOptionsFn: (input: string) => Promise<T[]>
    multiple?: boolean
    optionLabel?: keyof T
}): ReactElement => {
    const controller = useController({ name: source })

    const getInputValue = (field: any) => {
        return optionLabel ? field?.[optionLabel] : field?.label || field
    }

    const inputLabel = getInputValue(controller.field.value)

    const [options, setOptions] = useState<T[]>([])

    const [inputValue, setInputValue] = useState(inputLabel || '')
    const debouncedInputValue = useDebounce<string>(inputValue, 300)

    useEffect(() => {
        if (!debouncedInputValue.length) {
            return
        }
        const onChange = async (input: string) => {
            const res = await asyncOptionsFn(input)
            return setOptions(res)
        }
        onChange(debouncedInputValue)
    }, [debouncedInputValue, asyncOptionsFn])

    return (
        <Controller
            defaultValue={null}
            render={({ field, fieldState }) => {
                return (
                    <Autocomplete
                        style={{ width: 250, marginTop: 10, marginBottom: 10 }}
                        options={options}
                        multiple={multiple}
                        getOptionLabel={(option) => getInputValue(option)}
                        value={field.value}
                        inputValue={inputValue}
                        onBlur={field.onBlur}
                        onInputChange={(_, value) => {
                            setInputValue(value)
                        }}
                        filterOptions={(x) => x}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                variant="outlined"
                            />
                        )}
                        onChange={(_, value) => {
                            const fieldValue = getInputValue(value) || ''
                            setInputValue(fieldValue)
                            field.onChange(value)
                        }}
                        {...fieldState}
                    />
                )
            }}
            name={source}
        />
    )
}

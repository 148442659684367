import React from 'react'
import { useGetOne } from 'react-admin'
import { RaRecord } from '../../types'
import { resourcesIds } from '../../resources'
import { useQueryParams } from '../../hooks/useQueryParams'

type DropWatchTimeResponseBody = {
    extension: string
    imageB64: string
    wishlistsError: boolean
}

export const PredictWatchTime = () => {
    const dropId = useQueryParams('value')

    const { data } = useGetOne<RaRecord<DropWatchTimeResponseBody>>(
        resourcesIds.PREDICT_WATCH_TIME,
        {
            id: dropId || '',
        },
        {
            enabled: !!dropId,
        }
    )

    if (!dropId || !data?.imageB64) {
        return null
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img
                alt="DropWatchTimePrediction"
                src={`data:image/${data.extension};base64,${data.imageB64}`}
            />
            {data.wishlistsError && (
                <h3 style={{ color: 'red', margin: '20px auto' }}>
                    There was an error with fetching wishlists - final results
                    could be slightly skewed!
                </h3>
            )}
        </div>
    )
}

import { ReactElement } from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FilterOption } from 'types'
import { FormContext } from 'components/Form'

const FilterAutocomplete = ({
    source,
    options,
    label,
    onChange,
    multiple = true,
}: {
    label?: string
    source: string
    options: FilterOption[]
    onChange?: (input: string) => void
    multiple?: boolean
}): ReactElement => (
    <FormContext.Consumer>
        {({ handleFormChange, form }) => (
            <Autocomplete
                options={options}
                value={form[source] || []}
                onChange={(e, v) => handleFormChange(source, v)}
                multiple={multiple}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                    <MuiTextField
                        {...params}
                        label={label}
                        variant="outlined"
                        onChange={(e) => onChange && onChange(e.target.value)}
                    />
                )}
            />
        )}
    </FormContext.Consumer>
)

export default FilterAutocomplete

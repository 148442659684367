import {
    Datagrid,
    NumberField,
    DateField,
    TextField,
    DeleteWithConfirmButton,
    List,
    CreateButton,
    ExportButton,
} from 'react-admin'
import { isProd } from 'constants/app'

const CampaignStreamsListActions = () => (
    <div>
        {!isProd && <CreateButton />}
        <ExportButton />
    </div>
)

const CampaignStreamsList = () => (
    <List
        disableSyncWithLocation
        actions={<CampaignStreamsListActions />}
        empty={false}
        title="Campaign streams"
    >
        <Datagrid sort={{ field: 'userId', order: 'ASC' }}>
            <TextField label="Creator Id" source="userId" />
            <TextField label="Stream Id" source="id" />
            <NumberField label="CCV" source="averageViewers" />
            <DateField label="Started at" source="startedAt" showTime />
            <DateField label="Ended at" source="endedAt" showTime />
            <NumberField label="Duration [min]" source="durationMinutes" />
            <TextField label="Game ID" source="gameId" />
            <DeleteWithConfirmButton />
        </Datagrid>
    </List>
)

export default CampaignStreamsList

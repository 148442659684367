import { useState } from 'react'

type HelperTextParams<Event, InitialValue = string> = {
    event?: Event
    initialValue?: InitialValue
}

export type HelperTextHandler<Event, InitialValue = string> = (
    payload: HelperTextParams<Event, InitialValue>
) => string

export const useHelperText = <Event, InitialValue = string>(
    handler: HelperTextHandler<Event, InitialValue>,
    initialParams?: InitialValue
) => {
    const initialValue =
        initialParams && handler({ initialValue: initialParams })
    const [helperText, setHelperText] = useState<string>(initialValue ?? '')

    const setHelperTextHandler = (event: Event) => {
        setHelperText(handler({ event }))
    }

    return { helperText, setHelperText: setHelperTextHandler }
}

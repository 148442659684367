import { useSafeQueryParamsSource } from 'hooks/useQueryParams'
import { z } from 'zod'

const toOption = (value: string) => ({
    value,
    label: value,
})

export const useRequestCreatorsReportFormSource = () => {
    return useSafeQueryParamsSource(
        z.object({
            gameIds: z
                .array(z.coerce.string())
                .optional()
                .transform((value) => value?.map(toOption)),
            creatorIds: z
                .array(z.coerce.string())
                .optional()
                .transform((value) => value?.map(toOption)),
            languages: z
                .array(z.string())
                .optional()
                .transform((value) => value?.map(toOption)),
            excludedGameIds: z
                .array(z.string())
                .optional()
                .transform((value) => value?.map(toOption)),
            minCcv: z.coerce.number().optional(),
            maxCcv: z.coerce.number().optional(),
            hasTiktok: z.coerce.boolean().optional(),
            hasEmail: z.coerce.boolean().optional(),
            contacted: z.coerce.boolean().optional(),
            dateFrom: z.string().optional(),
            dateTo: z.string().optional(),
        })
    )
}

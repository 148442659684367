import stackConfig from '../config/stackConfig'

export const isProd = process.env.REACT_APP_ENV === 'production'

const domain = stackConfig?.AWS_STACK_DOMAIN ?? process.env.REACT_APP_DOMAIN

const domains = {
    staging: 'staging.ddstaging.link',
    production: 'daredrop.com',
    development: domain ? `dev.${domain}` : 'dev.watt.tv',
}

// eslint-disable-next-line no-nested-ternary
export const stackDomain =
    domains[process.env.REACT_APP_ENV as keyof typeof domains] ??
    domains.development
export const daredropUrl = `https://${stackDomain}`
export const daredropApiUrl = `https://api.${stackDomain}`
export const authApiUrl = `https://authorization.${stackDomain}`

const daredropDomain = isProd ? 'daredrop.com' : 'dev.watt.tv'
export const creatorApiUrl = `https://creator-api.${daredropDomain}`

import React from 'react'
import { DOLLARS_TO_POINTS_COEFFICIENT } from 'constants/converter'
import { HelperTextHandler } from '../hooks/useHelperText'

export const pointsToDollarsHandler: HelperTextHandler<
    React.ChangeEvent<HTMLInputElement>,
    number
> = ({ event, initialValue }) => {
    const value =
        initialValue ?? Math.floor(parseInt(event?.target?.value as string, 10))
    if (Number.isNaN(value)) {
        return 'Equivalent $0 dollar value'
    }
    return `Equivalent $${(
        value / DOLLARS_TO_POINTS_COEFFICIENT
    ).toLocaleString()} dollar value`
}

import { ComponentType } from 'react'
import { List, Datagrid, TextField, EditButton, NumberField } from 'react-admin'

const PointsMarketplaceItems: ComponentType = () => (
    <List empty={false} title="Marketplace Items">
        <Datagrid>
            <TextField label="Name" source="itemName" />
            <TextField label="Category" source="category" />
            <NumberField label="Cost (points)" source="cost" />
            <NumberField label="Quantity" source="quantity" />
            <TextField label="ID" source="id" />
            <EditButton label="Edit Item" />
        </Datagrid>
    </List>
)

export default PointsMarketplaceItems

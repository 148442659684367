import { ComponentType } from 'react'
import { CloudwatchMetric } from 'utils/creator-api/models/response/getStackMetricsResponse'
import Chart from 'components/Chart'

const styles = {
    container: { borderBottom: '2px solid grey' },
    chartsContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap' as const,
        gap: 20,
        justifyContent: 'center',
        paddingBottom: 10,
    },
    chartWrapper: {
        width: '25vw',
        minWidth: 400,
    },
}

const RdsMetrics: ComponentType<{
    rdsMetrics: Record<string, CloudwatchMetric[]>
}> = ({ rdsMetrics }) => (
    <div style={styles.container}>
        <h2>DB metrics:</h2>
        {Object.entries(rdsMetrics).map(([instanceIdentifier, metrics]) => (
            <div key={instanceIdentifier}>
                <h3>
                    <a
                        target="_blank"
                        href={`https://us-east-1.console.aws.amazon.com/rds/home?region=us-east-1#database:id=${instanceIdentifier};is-cluster=false;tab=monitoring`}
                        rel="noreferrer"
                    >
                        {instanceIdentifier}
                    </a>
                </h3>
                <div style={styles.chartsContainer}>
                    {metrics.map(
                        ({ metricName, formattedTimestamps, metricValues }) => (
                            <div style={styles.chartWrapper} key={metricName}>
                                <Chart
                                    title={metricName}
                                    labels={formattedTimestamps}
                                    values={metricValues}
                                />
                            </div>
                        )
                    )}
                </div>
            </div>
        ))}
    </div>
)

export default RdsMetrics

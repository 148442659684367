import * as React from 'react'
import { AnchorHTMLAttributes, memo, FC } from 'react'
import { Typography, Link } from '@mui/material'
import { useRecordContext } from 'react-admin'

export const LabeledUrlField: FC<UrlFieldProps> = memo((props) => {
    const { className, source, labelSource, ...rest } = props
    const record = useRecordContext(props)
    const value = record?.[source]
    const text = record?.[labelSource]

    if (value == null || value === undefined) {
        return (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...rest}
            />
        )
    }

    return (
        <Link className={className} href={value} variant="body2" {...rest}>
            {text || value}
        </Link>
    )
})

LabeledUrlField.displayName = 'UrlField'

interface UrlFieldProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    source: string
    labelSource: string
}

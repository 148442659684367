import Form from 'components/Form'
import FilterAutocomplete from 'components/FilterAutocomplete'
import FieldNumeric from 'components/FieldNumeric'
import FieldText from 'components/FieldText'
import { YesNoFilter } from 'components/YesNoFilter'
import SelectField from 'components/SelectField'
import { useCreatorsReportTiktokForm } from './hooks'

const style = {
    marginTop: 50,
    width: 280,
    margin: '0 auto',
    display: 'grid',
    gridGap: 20,
}

export const CreatorsReportTiktokForm = () => {
    const { filterOptions, handleSubmit, formLoading } =
        useCreatorsReportTiktokForm()

    if (formLoading) return <div>Loading...</div>

    if (!filterOptions) return <div>Error</div>

    return (
        <div>
            <Form
                style={style}
                submits={[{ onSubmit: handleSubmit }]}
                defaults={{}}
                clear={false}
            >
                <FilterAutocomplete
                    options={filterOptions.topics}
                    source="topics"
                    label="Topics"
                />
                <FilterAutocomplete
                    options={filterOptions.topics}
                    source="excludedTopics"
                    label="Excluded Topics"
                />
                <FilterAutocomplete
                    options={filterOptions.countries}
                    source="residentCountries"
                    label="Resident Countries"
                />
                <FilterAutocomplete
                    options={filterOptions.countries}
                    source="regions"
                    label="Regions"
                />
                <FieldText source="brandName" label="Brand" />
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <FieldNumeric
                        label="Followers (min)"
                        source="followersMin"
                    />
                    <FieldNumeric
                        label="Followers (max)"
                        source="followersMax"
                    />
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <FieldNumeric
                        label="Engagement Rate (min)"
                        source="engagementRateMin"
                    />
                    <FieldNumeric
                        label="Engagement Rate (max)"
                        source="engagementRateMax"
                    />
                </div>
                <SelectField
                    options={filterOptions.ageGroups}
                    label="Main Audience Age Group"
                    source="audienceAgeScope"
                />
                <div style={{ display: 'flex', gap: 20 }}>
                    <SelectField
                        options={filterOptions.genders}
                        label="Audience Gender"
                        source="audienceGenderScope"
                    />
                    <FieldNumeric
                        label="Percentage"
                        source="audienceGenderMinDecimal"
                    />
                </div>
                <YesNoFilter
                    label="Open to Invitation"
                    source="receiveInvitationsSettingOn"
                />
                <YesNoFilter label="Has Email" source="hasEmail" />
            </Form>
        </div>
    )
}

import {
    List,
    Datagrid,
    TextField,
    DeleteWithConfirmButton,
    DateField,
    TextInput,
    SelectInput,
    FilterForm,
    EditButton,
    Pagination,
} from 'react-admin'

const styles = {
    form: {
        display: 'flex',
        justifyContent: 'center',
        items: 'center',
        alignItems: 'center',
    },
    inputGroup: {
        margin: 2,
        display: 'flex',
        minWidth: 300,
    },
    formBody: {
        display: 'flex',
    },
    filterBar: {
        margin: 10,
    },
}

const filters = [
    { id: 'userId', name: 'User id' },
    { id: 'email', name: 'Email' },
]

export const ListToolbar = ({
    choices,
}: {
    choices: { id: string; name: string }[]
}) => (
    <div style={styles.filterBar}>
        <FilterForm
            filters={[
                <TextInput
                    key="search"
                    label="Search"
                    source="value"
                    alwaysOn
                />,
                <SelectInput
                    key="filter"
                    label="Filter"
                    source="filter"
                    alwaysOn
                    choices={choices}
                />,
            ]}
        />
    </div>
)

const UsersList = () => (
    <List
        empty={false}
        title="Users list"
        pagination={
            <Pagination rowsPerPageOptions={[25, 50, 100, 500, 1000, 2000]} />
        }
    >
        <div>
            <ListToolbar choices={filters} />
            <Datagrid>
                <TextField source="pk" label="User Id" />
                <TextField source="email" />
                <TextField source="groups" />
                <DateField source="created" />
                <DateField label="Confirmed at" source="confirmedAt" />
                <DeleteWithConfirmButton />
                <EditButton />
            </Datagrid>
        </div>
    </List>
)

export default UsersList

import { ComponentType } from 'react'
import moment from 'moment-mini'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { LogGroup } from 'utils/creator-api/models/response/getStackMetricsResponse'

const maxMessageLength = 500

const styles = {
    tableContainer: {
        maxHeight: 600,
        overflowY: 'scroll' as const,
    },
    tableCell: {
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        overflowWrap: 'anywhere',
    },
}

const getLogStreamUrl = (logGroupName: string, logStreamName: string) =>
    `${`https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/${logGroupName}`}/log-events/${encodeURIComponent(
        logStreamName
    )}`

const getLogGroupUrl = (logGroupName: string) =>
    `https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/${logGroupName}`

const LogsTable: ComponentType<{ logGroup: LogGroup }> = ({ logGroup }) => {
    const { logGroupName, logStreams } = logGroup
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Log stream name</TableCell>
                        <TableCell align="center">Message</TableCell>
                        <TableCell align="center">Timestamp</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logStreams.map(({ logStreamName, message, timestamp }) => {
                        const messageLimited = message.substring(
                            0,
                            maxMessageLength
                        )
                        const logMessage = `${messageLimited}${
                            messageLimited.length === maxMessageLength
                                ? '...'
                                : ''
                        }`
                        return (
                            <TableRow
                                key={logStreamName}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: '20%',
                                        ...styles.tableCell,
                                    }}
                                >
                                    <a
                                        href={getLogStreamUrl(
                                            logGroupName,
                                            logStreamName
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {logStreamName.split('/')[0]}
                                    </a>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: '70%',
                                        ...styles.tableCell,
                                    }}
                                >
                                    {logMessage}
                                </TableCell>
                                <TableCell align="center" sx={{ width: '10%' }}>
                                    {moment(timestamp).utc().format()}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const MonitoringLogs: ComponentType<{ logGroup: LogGroup }> = ({
    logGroup,
}) => (
    <div>
        <h2>
            <span>Last </span>
            <a
                href={getLogGroupUrl(logGroup.logGroupName)}
                target="_blank"
                rel="noreferrer"
            >
                {logGroup.logGroupName}
            </a>
            <span> streams:</span>
        </h2>
        <div style={styles.tableContainer}>
            <LogsTable logGroup={logGroup} />
        </div>
    </div>
)

export default MonitoringLogs

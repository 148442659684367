import campaignDetail from 'resourceDescriptions/campaign-points-details'
import campaignStreams from 'resourceDescriptions/campaign-streams'
import campaignTransactions from 'resourceDescriptions/campaign-transactions'
import campaigns from 'resourceDescriptions/campaigns'
import pointsMarketplace from 'resourceDescriptions/points-marketplace'
import pointsStatistics from 'resourceDescriptions/points-statistics'
import pointsTransactions from 'resourceDescriptions/points-transactions'
import previousDares from 'resourceDescriptions/previous-dares'
import { rewardActions } from 'resourceDescriptions/rewards'
import signedUpCreators from 'resourceDescriptions/signed-up-creators'
import usersList from 'resourceDescriptions/users-list'
import { resourcesIds } from 'resources'
import { ResourceParamType } from 'types'
import { verifications } from 'resourceDescriptions/verifications'
import manageCampaign from './manage-campaign'
import { pointsApplicationList } from './points-application-list'
import { campaignSurvey } from './campaign-survey'
import { predictWatchTime } from './predict-watch-time'
import { campaignTerms } from './campaign-terms'

export default {
    ...campaigns,
    ...campaignDetail,
    ...signedUpCreators,
    ...campaignStreams,
    ...campaignTransactions,
    ...usersList,
    ...pointsMarketplace,
    ...pointsTransactions,
    ...pointsStatistics,
    ...manageCampaign,
    ...previousDares,
    ...verifications,
    ...pointsApplicationList,
    ...campaignSurvey,
    ...campaignTerms,
    [resourcesIds.REWARDS_RESOURCE_ID]: rewardActions as unknown,
    ...predictWatchTime,
} as {
    [key: string]: {
        [key: string]: <T>(param?: ResourceParamType<T>) => Record<string, any>
    }
}

import { Edit, SimpleForm, useInput, NumberInput } from 'react-admin'
import moment from 'moment'
import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import {
    FormControl,
    TextField as MuiTextField,
    TextFieldProps,
} from '@material-ui/core'
import { resourcesIds } from '../../resources'

export const CampaignTermsForm = () => {
    return (
        <Edit
            resource={resourcesIds.CAMPAIGN_TERMS}
            mutationMode="pessimistic"
            redirect={(resourceId, _, data) => {
                if (data && 'dropId' in data) {
                    return `${resourceId}/${data.dropId}`
                }
                return ''
            }}
        >
            <SimpleForm>
                <div style={{ width: '100%' }}>
                    {/* <p>{title}</p> */}
                    <hr />
                    <div
                        style={{
                            width: '40%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <CampaignTermsAcceptedForm />
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}

const CampaignTermsAcceptedForm = () => {
    const { field, fieldState } = useInput({
        defaultValue: null,
        source: 'pointsReservationDueDate',
    })

    return (
        <div>
            <FormControl error={Boolean(fieldState.error)}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        views={['day', 'hours']}
                        label="Points reservation due date"
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) => {
                            field.onChange(
                                moment(date).startOf('hour').utc().format()
                            )
                        }}
                        renderInput={(params) => (
                            <MuiTextField
                                {...(params as TextFieldProps)}
                                style={{ paddingBottom: 30 }}
                                size="small"
                                variant="filled"
                                defaultValue={null}
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                    <NumberInput
                        source="reservedPointsTotal"
                        label="Reserved points total"
                    />
                    <NumberInput
                        source="reservedPointsLeft"
                        label="Reserved points left"
                    />
                    <NumberInput
                        source="estimatedPointsToEarn"
                        label="Estimated points to earn"
                    />
                </LocalizationProvider>
            </FormControl>
        </div>
    )
}

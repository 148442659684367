export function downloadFile(url: string) {
    const urlNameArr = url.split('/')
    const name = urlNameArr[urlNameArr.length - 1]
    const link = document.createElement('a')
    link.setAttribute('download', name)
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
}

import { z } from 'zod'
import ajax, { HTTP_STATUS_CODES } from 'utils/ajax'
import { daredropApiUrl } from 'constants/app'
import { notify } from 'components/LayoutWithNotify'
import authProvider from '../../authProvider'
import { AuthService } from '../../services/auth.service'
import { TokensService } from '../../services/tokens.service'

export type DareDropApiRequestBaseConfig = {
    payload: unknown
    endpointId: string
}

type DareDropApiRequestConfig<TSchema> = DareDropApiRequestBaseConfig & {
    schema: z.ZodType<TSchema>
}

type DareDropApiError = { message: string }

type DareDropApiSuccessResponse<T> = { statusCode: 200; body: T }

type DareDropApiFailResponse = { statusCode: number; error: DareDropApiError }

type DareDropApiResponse<T> =
    | DareDropApiSuccessResponse<T>
    | DareDropApiFailResponse

const isSuccessResponse = <T>(
    response: unknown
): response is DareDropApiSuccessResponse<T> =>
    typeof response === 'object' &&
    response !== null &&
    'statusCode' in response &&
    response.statusCode === HTTP_STATUS_CODES.OK

const daredropApiRequest = async <TSchema>(
    config: DareDropApiRequestConfig<TSchema>,
    retries = 1
): Promise<TSchema> => {
    const { schema, payload, endpointId } = config

    const response = await ajax<DareDropApiResponse<unknown>>({
        url: daredropApiUrl,
        body: {
            endpointId,
            payload,
            authentication: TokensService.getAppToken(),
        },
        method: 'POST',
        headers: {},
    })

    if (!isSuccessResponse(response)) {
        if (response.statusCode === HTTP_STATUS_CODES.UNAUTHORIZED) {
            if (retries > 0) {
                await AuthService.revalidateAuth()
                return daredropApiRequest(config, retries - 1)
            }
            await authProvider.logout()
        }
        notify?.(response.error.message, { type: 'error' })
        throw new Error(response.error.message)
    }

    return schema.parse(response.body)
}

export default daredropApiRequest

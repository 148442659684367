import { useState } from 'react'
import moment from 'moment-mini'
import Form from 'components/Form'
import FilterTwitchGamesAutocomplete from 'components/FilterTwitchGamesAutocomplete'
import { RadioField } from 'components/RadioField'
import { CloudUpload } from '@material-ui/icons'
import { Button } from 'react-admin'
import { Modal } from '@mui/material'
import DateRangePicker from 'components/DateRangePicker'
import { YesNoFilter } from 'components/YesNoFilter'
import { UploadCreatorListModal } from 'components/UploadCreatorListModal'
import FilterTwitchUsersAutocomplete from 'components/FilterTwitchUsersAutocomplete'
import { CcvField } from 'components/CcvField'
import FilterAutocomplete from 'components/FilterAutocomplete'
import languageOptions from 'utils/languageOptions'
import { useRequestCreatorsForm } from 'views/creators-report/hooks/useRequestCreatorsForm'

const DatePicker = () => (
    <RadioField
        source="dateFrom"
        label="Date range"
        options={[
            {
                value: moment().subtract(7, 'days').format(),
                label: '7 days',
            },
            {
                value: moment().subtract(14, 'days').format(),
                label: '14 days',
            },
            {
                value: moment().subtract(30, 'days').format(),
                label: '30 days',
            },
            {
                value: moment().subtract(60, 'days').format(),
                label: '60 days',
            },
            {
                value: moment().subtract(90, 'days').format(),
                label: '90 days',
            },
            {
                value: moment().subtract(180, 'days').format(),
                label: '180 days',
            },
            {
                value: moment().subtract(1, 'year').format(),
                label: 'Year',
            },
        ]}
        onChange={(form) => {
            // eslint-disable-next-line no-param-reassign
            form.dateTo = moment().utc().endOf('day').format()
        }}
    />
)

const style = {
    width: 280,
    margin: '0 auto',
    display: 'grid',
    gridGap: 20,
}

const RequestCreatorsReportForm = () => {
    const [modalOpened, setModalOpened] = useState(false)
    const { source, onSubmit } = useRequestCreatorsForm()
    return (
        <div>
            <Button
                label="Upload contacted creators list"
                onClick={() => setModalOpened(true)}
                startIcon={<CloudUpload />}
            />
            <Modal open={modalOpened} onClose={() => setModalOpened(false)}>
                <UploadCreatorListModal setModalOpened={setModalOpened} />
            </Modal>
            <Form
                style={style}
                submits={[{ onSubmit }]}
                defaults={{
                    gameIds: [],
                    languages: [],
                    excludedGameIds: [],
                    ...source,
                }}
            >
                <FilterTwitchGamesAutocomplete
                    label="Games"
                    source="gameIds"
                    multiple
                />
                <FilterTwitchGamesAutocomplete
                    label="Excluded games"
                    source="excludedGameIds"
                    multiple
                />
                <FilterAutocomplete
                    label="Languages"
                    source="languages"
                    options={languageOptions}
                />
                <CcvField />
                <FilterTwitchUsersAutocomplete
                    source="creatorIds"
                    label="Creators"
                    multiple
                />
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <DatePicker />
                    <div>
                        <YesNoFilter label="Contacted" source="contacted" />
                        <YesNoFilter label="Has Email" source="hasEmail" />
                        <YesNoFilter label="Has TikTok" source="hasTiktok" />
                    </div>
                </div>
                <DateRangePicker
                    sourceFrom="dateFrom"
                    sourceTo="dateTo"
                    label="Date range (manual)"
                />
            </Form>
        </div>
    )
}

export default RequestCreatorsReportForm

import { SimpleForm, TextInput, DateTimeInput } from 'react-admin'
import { Box } from '@mui/material'
import { SelectStateInput } from './components/SelectStateInput'
import { ThreatsField } from './components/ThreatsField'
import { PessimisticEdit } from '../../components/PessimisticEdit'

export const EditVerification = () => {
    return (
        <PessimisticEdit>
            <SimpleForm>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <TextInput source="userId" label="User Id" disabled />
                    <TextInput source="platform" label="Platform" disabled />
                    <TextInput
                        source="platformId"
                        label="Platform Id"
                        disabled
                    />
                    <SelectStateInput source="state" />
                    <DateTimeInput source="created" disabled />
                    <DateTimeInput source="modified" disabled />
                    <TextInput label="Email" source="context.email" disabled />
                    <DateTimeInput
                        label="Account Created"
                        source="context.accountCreatedAt"
                        disabled
                    />
                    <ThreatsField />
                </Box>
            </SimpleForm>
        </PessimisticEdit>
    )
}

import {
    List,
    Datagrid,
    TextField,
    Pagination,
    ExportButton,
    TopToolbar,
    NumberField,
    FunctionField,
} from 'react-admin'
import { ListToolbar } from 'views/users-list/UsersList'
import { DropEvent } from '../../types'

const ListActions = () => (
    <TopToolbar>
        <ExportButton />
    </TopToolbar>
)

const filters = [{ id: 'game', name: 'Game title' }]

const PreviousDaresList = () => (
    <List
        empty={false}
        title="Users list"
        pagination={
            <Pagination rowsPerPageOptions={[25, 50, 100, 500, 1000, 2000]} />
        }
        actions={<ListActions />}
    >
        <div>
            <ListToolbar choices={filters} />
            <Datagrid isRowSelectable={() => false}>
                <TextField source="game" label="Game title" />
                <TextField source="title" />
                <TextField source="description" />
                <TextField source="product" label="Reward" />
                <TextField source="level" />
                <TextField source="type" />
                <FunctionField
                    label="Tier"
                    render={(record: DropEvent['dares'][number]) => {
                        if (!record.battlePass) {
                            return 'N/A'
                        }
                        return record.battlePass.tier
                    }}
                />
                <NumberField source="completed" />
                <NumberField source="started" />
            </Datagrid>
        </div>
    </List>
)

export default PreviousDaresList

import { resourcesIds } from '../resources'
import {
    createKey,
    deleteKey,
    editListItemsIdKey,
    editOneItemIdKey,
    getListKey,
    getOneKey,
    updateKey,
} from '../keys'
import { getCurrentQueryValue } from '../utils/common'

type CreateSurveyParams = {
    url: string
    surveyDate: string
}

type UpdateSurveyParams = {
    data: {
        dropId: string
        id: string
        url: string
        surveyDate: string
        isExpired: string
    }
}

export const campaignSurvey = {
    [resourcesIds.CAMPAIGN_SURVEY]: {
        [createKey]: (params: CreateSurveyParams) => {
            const dropId = getCurrentQueryValue()
            if (!dropId) {
                return undefined
            }

            return {
                endpointId: 'SAVE_SURVEY',
                payload: {
                    dropId,
                    url: params.url,
                    surveyDate: params.surveyDate,
                },
            }
        },
        [getListKey]: () => {
            const dropId = getCurrentQueryValue()
            if (!dropId) {
                return undefined
            }

            return {
                endpointId: 'GET_SURVEYS',
                payload: { dropId },
            }
        },
        [getOneKey]: ({ id }: { id: string }) => {
            return {
                endpointId: 'GET_SURVEY',
                payload: { id },
            }
        },
        [updateKey]: ({ data }: UpdateSurveyParams) => {
            return {
                endpointId: 'SAVE_SURVEY',
                payload: {
                    dropId: data.dropId,
                    id: data.id,
                    url: data.url,
                    surveyDate: data.surveyDate,
                },
            }
        },
        [editListItemsIdKey]: (rawData: Record<string, any>) => rawData,
        [editOneItemIdKey]: (rawData: Record<string, any>) => rawData || [],
        [deleteKey]: (params: { id: string }) => {
            return {
                endpointId: 'DELETE_SURVEY',
                payload: {
                    id: params.id,
                },
            }
        },
    },
}

import resourceDescriptions from 'resourceDescriptions'
import { ResourceParamType } from '../types'

export default <T>(
    resource: string,
    method: string,
    ...params: ResourceParamType<T>[]
): Promise<Record<string, any>> | Record<string, any> => {
    if (!resourceDescriptions[resource][method]) return {}
    return resourceDescriptions[resource][method](...params)
}

import { useRecordContext } from 'react-admin'
import { Button } from '@mui/material'
import {
    PointsDetails,
    Transaction,
} from 'resourceDescriptions/points-transactions'
import { DaredropApi } from 'utils/daredrop-api'

const PaidOffPlatformButton = ({
    render,
}: {
    render?: (item: Transaction | PointsDetails) => boolean
}) => {
    const record = useRecordContext<Transaction | PointsDetails>()

    const renderButton = () => (
        <Button
            onClick={() => DaredropApi.markTransactionAsPaidOffPlatform(record)}
        >
            Paid off-platform
        </Button>
    )

    if (render) {
        if (render(record)) {
            return renderButton()
        }
        return null
    }
    return renderButton()
}

export default PaidOffPlatformButton

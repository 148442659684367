import { createContext, ReactNode, useContext, useState } from 'react'

type DialogContext = {
    isOpened: boolean
    setIsOpened: (isOpened: boolean) => void
}

const dialogContext = createContext<DialogContext | null>(null)

export const useDialogContext = () => {
    const context = useContext(dialogContext)

    if (!context) {
        throw new Error(
            `useDialogContext must be used within a DialogContextProvider`
        )
    }

    return context
}

type DialogContextProviderProps = {
    children: ReactNode
}

export const DialogContextProvider = ({
    children,
}: DialogContextProviderProps) => {
    const [isOpened, setIsOpened] = useState(false)

    return (
        <dialogContext.Provider value={{ isOpened, setIsOpened }}>
            {children}
        </dialogContext.Provider>
    )
}
